import { FormikErrors, FormikProps } from 'formik';
import { profileContainer } from 'global/constants';
import { FileType } from 'global/enums/fileTypes';
import IApiError from 'global/interfaces/api';
import { IFileMetadata } from 'global/interfaces/file';
import { IBuyerProfile, IEditableUser, IUploadQueue } from 'global/interfaces/user';
import { saveImage } from 'services/storageService';
import { showError } from './errorHandler';
import { saveAs } from 'file-saver';
import { Dispatch, SetStateAction } from 'react';

export function getThumbnailId(id: string): string {
  return id + '_thumbnail';
}

export function makeBlobUrl(directory: string, id: string, extension: string, container: string = profileContainer) {
  if (id.startsWith('bunv_')) {
    return `${process.env.REACT_APP_BUNNY_VIDEO_CDN_URL ?? ''}/${id.replace('bunv_', '')}/playlist.m3u8`;
  }
  return `${process.env.REACT_APP_CDN_URL ?? ''}/${container}/${directory}/${id}${extension}`;
}

export function makeBunnyThumbnailUrl(id: string) {
  return `${process.env.REACT_APP_BUNNY_VIDEO_CDN_URL ?? ''}/${id.replace('bunv_', '')}/thumbnail.jpg`;
}

export function makeBlobUrlFromPath(subPath: string, container: string = profileContainer, isImage?: boolean) {
  return `${
    !isImage ? process.env.REACT_APP_CDN_URL : process.env.REACT_APP_IMAGE_CDN_URL ?? ''
  }/${container}/${subPath}${isImage ? '?width=500' : ''}`;
}

export async function getBase64Image(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(String(reader.result));
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export function getTempFileId(): string {
  return (Math.random() + 1).toString(36);
}

export function getFileType(type: string): FileType {
  if (type.includes('image/')) {
    return FileType.Image;
  }
  if (type.includes('video/')) {
    return FileType.Video;
  }
  if (type.includes('application/pdf')) {
    return FileType.Pdf;
  }
  return FileType.Other;
}

export function createFileFormData(file: File): FormData {
  const formData = new FormData();
  formData.append('file', file);
  return formData;
}

export function getPreUploadFormFile(file: File): IUploadQueue {
  const formFile: IUploadQueue = {
    isUploadCancelled: false,
    file,
    tempId: getTempFileId(),
  };
  return formFile;
}

export const handleProfileImgChange = async (
  file: File,
  form: FormikProps<IBuyerProfile> | FormikProps<IEditableUser>,
): Promise<string> =>
  await new Promise((resolve, reject) => {
    if (file !== null) {
      saveImage(file)
        .then((file: IFileMetadata) => {
          form.setFieldValue('profileImage', file);
          resolve(file.path);
        })
        .catch((err: IApiError) => {
          showError(err);
          reject(err.message);
        });
    }
  });

export const triggerFileDownload = (url: string, name: string, newWindow = false, type?: FileType) => {
  const isSafariiOS = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafariiOS) {
    clickAndCreateAnchor(url, name);
  } else if (newWindow && (type === FileType.Pdf || type === FileType.Image)) {
    clickAndCreateAnchor(url, name, true);
  } else {
    saveAs(url, name);
  }
};

function clickAndCreateAnchor(url: string, name: string, newWindow = false) {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.style.display = 'none';

  if (newWindow) {
    a.target = '_blank';
  }

  document.body.appendChild(a);
  a.click();

  if (a.parentNode === document.body) {
    document.body.removeChild(a);
  }
}

export const multiUploadFormatError = <T extends { [key: string]: any }>(form: FormikProps<T>, key: string) => {
  if (!form.touched[key]) {
    return undefined;
  }
  if (form.errors.isUploading) {
    return form.errors.isUploading;
  }
  if (typeof form.errors[key] === 'string') {
    return form.errors[key];
  }

  const errors = form.errors[key] as FormikErrors<IFileMetadata>[] | undefined;

  if (Array.isArray(errors) && errors.length > 0) {
    return errors.map(f => f?.name ?? '');
  }

  return undefined;
};

export const handleFileNameChange = (id: string, name: string, setFiles: Dispatch<SetStateAction<IFileMetadata[]>>) => {
  setFiles(currentFiles =>
    currentFiles.map(currentFile => (currentFile.id === id ? { ...currentFile, name } : currentFile)),
  );
};

export const handleAddFile = (file: IFileMetadata, setFiles: Dispatch<SetStateAction<IFileMetadata[]>>) => {
  setFiles(currentFiles => [
    ...currentFiles,
    {
      ...file,
    },
  ]);
};

export const handleMoveRightFile = (id: string, setFiles: Dispatch<SetStateAction<IFileMetadata[]>>): void => {
  setFiles(currentFiles => {
    const index = currentFiles.findIndex(file => file.id == id);
    const newArray = currentFiles.slice(0);
    const item = currentFiles[index];
    newArray.splice(index, 1);
    newArray.splice(index !== currentFiles.length - 1 ? index + 1 : currentFiles.length - 1, 0, item); // handle 0 index
    return newArray;
  });
};

export const handleMoveLeftFile = (id: string, setFiles: Dispatch<SetStateAction<IFileMetadata[]>>): void => {
  setFiles(currentFiles => {
    const index = currentFiles.findIndex(file => file.id == id);
    const newArray = currentFiles.slice(0);
    const item = currentFiles[index];
    newArray.splice(index, 1);
    newArray.splice(index !== 0 ? index - 1 : 0, 0, item); // handle 0 index
    return newArray;
  });
};

export const handleDeleteFile = (id: string, setFiles: Dispatch<SetStateAction<IFileMetadata[]>>): void => {
  setFiles(currentFiles => currentFiles.filter(file => file.id !== id));
};

export const onUploadStatusChange = <T extends { [key: string]: any }>(form: FormikProps<T>, status: boolean): void => {
  form.setFieldValue('isUploading', status);
  form.setFieldTouched('isUploading');
};
