import { Grid, Stack } from '@mui/material';

import ItemLatestOpportunities from './ItemLatestOpportunities';
import ItemLatestProposals from './ItemLatestProposals';
import ItemRefer from './ItemRefer';

export default function MainSection() {
  return (
    <Grid container mt={2} spacing={2}>
      <Grid item container xs={12} md={4}>
        <Stack direction="column" rowGap={2} width="100%">
          <ItemLatestOpportunities />
        </Stack>
      </Grid>

      <Grid item container xs={12} md={4}>
        <Stack direction="column" rowGap={2} width="100%">
          <ItemLatestProposals />
        </Stack>
      </Grid>

      <Grid item container xs={12} md={4}>
        <Stack direction="column" rowGap={2} width="100%">
          <ItemRefer />
        </Stack>
      </Grid>
    </Grid>
  );
}
