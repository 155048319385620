import { Box, Container, Stack, styled, Typography, useTheme } from '@mui/material';
import OpportunitySubscribeForm from './OpportunitySubscribeForm';
import { useContext, useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AuthContext } from 'contexts/AuthContext';
import { getNewsletterPreferences } from 'services/newsletterService';

const StyledMainBox = styled(Box)(() => ({
  backgroundColor: '#E7E5E0',
  display: 'flex',
  minHeight: '84px',
}));

export default function OpportunitySubscribe() {
  const [completed, setCompleted] = useState(false);
  const [show, setShow] = useState(false);
  const authContext = useContext(AuthContext);
  const theme = useTheme();

  useEffect(() => {
    setShow(false);
    if (!authContext.user) {
      setShow(true);
      return;
    }

    getNewsletterPreferences().then(res => {
      if (res) {
        setShow(false);
      } else {
        setShow(true);
      }
    });
  }, [authContext.user]);

  if (!show) {
    return <></>;
  }

  return (
    <StyledMainBox>
      <Container maxWidth="lg">
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          alignItems={{ xs: 'stretch', md: 'center' }}
          justifyContent={{ xs: 'space-evenly', md: 'space-between' }}
          sx={{ py: 3, width: '100%' }}
          alignContent="stretch"
        >
          {!completed ? (
            <OpportunitySubscribeForm setCompleted={setCompleted} />
          ) : (
            <Box marginTop={1} display={'flex'} alignItems={'center'}>
              <Typography variant="subtitle1" color={theme.palette.common.black} fontWeight={600} component={'span'}>
                You have subscribed to our Gig Newsletter
              </Typography>
              <CheckCircleIcon color="success" sx={{ ml: 1 }} />
            </Box>
          )}
        </Stack>
      </Container>
    </StyledMainBox>
  );
}
