export const mapToSearchParams = (formProps: Record<any, any>): URLSearchParams => {
  const newSearchParams = new URLSearchParams();
  Object.entries(formProps).forEach(([key, value]) => {
    if (value === undefined || value === null) return;
    if (Array.isArray(value)) {
      value.forEach(v => {
        if (v !== undefined && v !== null) {
          newSearchParams.append(key, String(v));
        }
      });
    } else {
      newSearchParams.append(key, String(value));
    }
  });
  return newSearchParams;
};
