import { AccessTime } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { UserAvatar } from 'components/common/UserAvatar';

import { IConnectionAttempt } from 'global/interfaces/connectionAttempt';
import { useEffect, useState } from 'react';

import { approve, getConnectionAttempts, reject } from 'services/connectionAttemptService';

import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';

interface DialogSettings {
  isOpen: boolean;
  question?: string;
  description?: string;
  onAgree?: () => void;
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: '16px',
  boxShadow: '0px 2px 12px 0px #1721551A',

  '&.MuiPaper-root': {
    borderRadius: '7px',
    padding: '16px',

    '&:before': {
      display: 'none',
    },
  },

  '&:last-of-type': {
    marginBottom: 0,
  },
  [theme.breakpoints.up('md')]: {
    '&.MuiPaper-root': {
      '&:hover': {
        borderColor: '#4B56D8',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    '&.MuiPaper-root': {
      borderRadius: '8px',
      padding: '8px',
    },
  },
}));

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  'svg[data-testid="ExpandMoreIcon"]': {
    display: 'inline-block',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandMoreIcon"]': {
    display: 'none',
  },
  'svg[data-testid="ExpandLessIcon"]': {
    display: 'none',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandLessIcon"]': {
    display: 'inline-block',
  },
}));

interface StyledAvatarProps {
  isLarge?: boolean;
}

const StyledUserAvatar = styled(UserAvatar, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isLarge',
})<StyledAvatarProps>(({ theme, isLarge }) => ({
  height: isLarge ? '38px' : '28px',
  width: isLarge ? '38px' : '28px',
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[500],
}));

const StyledImageBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isLarge',
})<StyledAvatarProps>(({ isLarge }) => ({
  height: isLarge ? '38px' : '28px',
  width: isLarge ? '38px' : '28px',
  position: 'relative',
  borderRadius: '8px',
}));

const StyledStack = styled(Stack)(() => ({
  cursor: 'pointer',
}));

interface IBasicUserInfoProps {
  userId: string;
  displayName: string;
  profileTitle: string | null;
  isMobile: boolean;
}

function BasicUserInfo({ userId, displayName, profileTitle, isMobile }: IBasicUserInfoProps) {
  return (
    <Stack
      direction="row"
      gap={profileTitle !== undefined && profileTitle != null && profileTitle.length > 0 ? 1.5 : 1.0}
      alignItems="center"
    >
      <StyledImageBox isLarge={profileTitle !== undefined && profileTitle != null && profileTitle.length > 0}>
        <StyledUserAvatar
          userId={userId}
          displayName={displayName}
          isLarge={profileTitle !== undefined && profileTitle != null && profileTitle.length > 0}
        />
      </StyledImageBox>

      <StyledStack>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} textTransform="capitalize">
            {displayName}
          </Typography>
        </Stack>

        <Typography variant="body2">{profileTitle}</Typography>
      </StyledStack>
    </Stack>
  );
}

export default function ViewConnectionAttempts() {
  useTitle('Admin - Manage Connection Attempts');
  const [attempts, setAttempts] = useState<IConnectionAttempt[]>([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [dialogSettings, setDialogSettings] = useState<DialogSettings>({ isOpen: false });
  const { isOpen, question, description, onAgree } = dialogSettings;

  const fetchAttempts = () => {
    getConnectionAttempts()
      .then((retrievedAttempts: IConnectionAttempt[]) => {
        setAttempts(retrievedAttempts);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
        setRefresh(false);
      });
  };

  useEffect(() => {
    if (refresh) {
      setLoading(true);
      fetchAttempts();
    }
  }, [refresh]);

  const handleApprove = (connectionAttemptId: string, fromName: string, toName: string): void => {
    setDialogSettings({
      isOpen: true,
      question: `Approve message from ${fromName}?`,
      description: `Approval of this message to ${toName} will send them this message immediately. Do you want to continue?`,
      onAgree: () => {
        setDialogSettings({ isOpen: false });
        approve(connectionAttemptId).finally(() => {
          setRefresh(true);
        });
      },
    });
  };

  const handleReject = (connectionAttemptId: string, fromName: string, toName: string): void => {
    setDialogSettings({
      isOpen: true,
      question: `Reject message from ${fromName}?`,
      description: `Rejecting this message to ${toName} will will delete the message from the queue. No further action will be taken. Do you want to continue?`,
      onAgree: () => {
        setDialogSettings({ isOpen: false });
        reject(connectionAttemptId).finally(() => {
          setRefresh(true);
        });
      },
    });
  };

  return (
    <Container maxWidth="lg">
      <Grid container mt={6}>
        {loading && (
          <Grid item xs={12} sx={{ mb: 5 }}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {!loading && (
          <Grid item xs={12} sx={{ mb: 6 }}>
            {attempts.map((connectionAttempt: IConnectionAttempt, index: number) => (
              <StyledAccordion key={index} elevation={10} disableGutters>
                <StyledAccordionSummary>
                  <JustifiedBox>
                    <Stack direction="column" gap={isMobile ? 0.75 : 1} width="100%">
                      <Typography variant="subtitle1">{connectionAttempt.displayName}</Typography>

                      {isMobile && (
                        <Stack direction={'row'} gap={0.5} minWidth={'100px'} mt={0.5}>
                          <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
                          <Typography variant="body2" color={theme.palette.grey[500]}>
                            {connectionAttempt.agoDesc}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    {!isMobile && (
                      <Stack direction={'row'} gap={0.5} minWidth={'100px'}>
                        <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
                        <Typography variant="body2" color={theme.palette.grey[500]}>
                          {connectionAttempt.agoDesc}
                        </Typography>
                      </Stack>
                    )}
                  </JustifiedBox>
                </StyledAccordionSummary>

                <AccordionDetails>
                  <Stack direction="column" spacing={0} width="100%" gap={3}>
                    <Typography variant={isMobile ? 'body2' : 'body1'}>
                      Sender Email: {connectionAttempt.email}
                    </Typography>
                    <Typography variant={isMobile ? 'body2' : 'body1'}>Sent To:</Typography>
                    <BasicUserInfo
                      userId={connectionAttempt.otherUserId}
                      displayName={connectionAttempt.otherDisplayName}
                      profileTitle={connectionAttempt.otherProfileTitle}
                      isMobile={isMobile}
                    />
                    <Typography variant={isMobile ? 'body2' : 'body1'}>Subject: {connectionAttempt.subject}</Typography>
                    <Typography variant={isMobile ? 'body2' : 'body1'}>Message:</Typography>
                    <Typography variant={isMobile ? 'body2' : 'body1'} whiteSpace="break-spaces">
                      {connectionAttempt.message}
                    </Typography>
                    <Grid container gap={2}>
                      <RoundButton
                        variant="contained"
                        onClick={() => {
                          handleApprove(
                            connectionAttempt.id,
                            connectionAttempt.displayName,
                            connectionAttempt.otherDisplayName,
                          );
                        }}
                      >
                        Approve
                      </RoundButton>
                      <RoundButton
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleReject(
                            connectionAttempt.id,
                            connectionAttempt.displayName,
                            connectionAttempt.otherDisplayName,
                          );
                        }}
                      >
                        Reject
                      </RoundButton>
                    </Grid>
                  </Stack>
                </AccordionDetails>
              </StyledAccordion>
            ))}
            {attempts.length == 0 && (
              <Typography variant="body1" color={theme.palette.grey[500]}>
                No connection attempts awaiting review
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <RoundButton onClick={() => setDialogSettings({ isOpen: false })}>No</RoundButton>
          <RoundButton variant="contained" onClick={onAgree} autoFocus>
            Yes
          </RoundButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
