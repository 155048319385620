import CopyToClipboardButton from 'components/common/Button/CopyToClipboardButton';
import { MainSectionBox } from './MainSectionBox';
import { Box, Grow, Stack, styled, Typography, useTheme } from '@mui/material';
import { getReferralUrl } from 'utils/user';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export default function ItemRefer() {
  const theme = useTheme();
  const authContext = useContext(AuthContext);
  return (
    <MainSectionBox>
      <JustifiedBox>
        <Stack direction="row" spacing={1.5}>
          <Typography variant="subtitle1" fontWeight={600} mt={0.5}>
            Refer a Friend
          </Typography>
        </Stack>
      </JustifiedBox>

      <Grow in={true} timeout={500}>
        <Box mb={2}>
          <Typography variant="body2" mt={3.0}>
            Refer a friend and get a month of premium for FREE when they sign up to the premium plan. Each friend will
            also receive 50% off their first month of premium.
          </Typography>

          <Typography variant="body2" mt={2.0}>
            Refer 5 friends and we will give you a whole year for FREE!
          </Typography>
          <CopyToClipboardButton
            textToCopyToClipboard={getReferralUrl(authContext.user?.id ?? '')}
            buttonLabel="Copy link"
            roundButton={true}
            sx={{
              padding: '14px 28px',
              borderRadius: theme.spacing(6),
              marginTop: '16px',

              [theme.breakpoints.down('sm')]: {
                padding: '12px 22px',
              },
            }}
          />
        </Box>
      </Grow>
    </MainSectionBox>
  );
}
