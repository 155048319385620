import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';

interface PriceFeature {
  text: string;
}

const sellerFreePriceFeatures: PriceFeature[] = [
  { text: 'Submit proposals for up to 150 selected free gigs every month' },
  {
    text: 'Create a profile and get listed on our talent network to be discovered by prospective clients',
  },
  { text: 'Daily/weekly email alerts with fresh, curated freelance gigs directly to your inbox' },
];

const sellerPremPriceFeatures: PriceFeature[] = [
  {
    text: 'Centralised access to 1,500+ high-quality freelance gigs per month, sourced from 40+ trusted sites',
  },
  {
    text: 'Instantly access 65% of gigs and full access to the rest once you have a verified profile',
  },
  {
    text: 'Real-time personalised Slack alerts so you can pitch early',
  },
  {
    text: 'Join our private Slack community of vetted freelancers to learn from and collaborate with',
  },
  {
    text: 'Track your proposals for selected gigs to see if they are being viewed or clicked',
  },

  { text: 'Boosted profile visibility on our talent network (clients don’t need to sign up to contact you)' },
];

interface PricingTableProps {
  isSeller: boolean;
  currencySybmol: string;
  premPrice: number;
  freeBgColor?: string;
  freeCTA?: React.ReactNode;
  premCTA?: React.ReactNode;
}

const StyledPriceBox = styled(Box)(() => ({
  padding: '40px 32px 40px 32px',
  borderRadius: '24px',
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

interface StyledFreePriceBoxProps {
  freeBgColor?: string;
}

const StyledFreePriceBox = styled(StyledPriceBox, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'freeBgColor',
})<StyledFreePriceBoxProps>(() => ({
  background: 'linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%)',
  border: '1px solid #D9D8D6',
}));

const StyledPremiumPriceBox = styled(StyledPriceBox)(() => ({
  background: 'linear-gradient(180deg, #F1F4FE 0%, #EAEEFA 100%)',

  border: '1px solid #2D5CE6',
}));

const PriceFeatureTypography = styled(Typography)(() => ({
  lineHeight: '140%',
  fontSize: '16px',

  color: '#101828',
}));

const PriceFreeFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#101828',
}));

const PricePremFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#101828',
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#121F31',
  marginRight: '16px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

export default function PricingTable({ currencySybmol, premPrice, freeBgColor, freeCTA, premCTA }: PricingTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container spacing={{ xs: 0, md: 4 }} rowSpacing={4}>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-end' }}>
        <StyledFreePriceBox freeBgColor={freeBgColor}>
          <Box mb={isMobile ? 1 : 3}>
            <Typography variant="h6" fontWeight={500} fontSize={'36px'} color={'#101828'}>
              Free
            </Typography>
            <Typography
              variant="body1"
              color={'#5E6779'}
              mt={1.5}
              component={'div'}
              fontWeight={700}
              fontSize={{ xs: '16px', lg: '18px' }}
            >
              {currencySybmol}0 per month
              {!isMobile && (
                <>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}
            </Typography>
            <Divider sx={{ mt: 2.25 }} color="#9EA5B3"></Divider>
            <List sx={{ mt: isMobile ? 1.5 : 2.5 }}>
              {sellerFreePriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PriceFreeFeatureTypography variant="body1">{feature.text}</PriceFreeFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <Box width={'100%'}> {freeCTA}</Box>
          </Box>
        </StyledFreePriceBox>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-start' }}>
        <StyledPremiumPriceBox>
          <Box mb={isMobile ? 1 : 3}>
            <Typography variant="h6" fontWeight={500} fontSize={'36px'} color={'#101828'}>
              Premium
            </Typography>

            <Typography
              variant="body1"
              color={'#5E6779'}
              mt={1.5}
              component={'div'}
              fontWeight={700}
              fontSize={{ xs: '16px', lg: '18px' }}
            >
              {currencySybmol}
              {premPrice} per month
            </Typography>
            <Typography variant="body1" color={'#5E6779'} component={'div'}>
              Cancel anytime in settings
            </Typography>

            <Divider sx={{ mt: 2.5 }} color="#9EA5B3"></Divider>

            <List sx={{ mt: isMobile ? 1.5 : 2.5 }}>
              {sellerPremPriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: p != 0 ? 1 : 0 }}>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          {premCTA}
        </StyledPremiumPriceBox>
      </Grid>
    </Grid>
  );
}
