import { MainSectionBox } from './MainSectionBox';
import { Box, Button, CircularProgress, Grid, Grow, Stack, styled, Typography, useTheme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { showError } from 'utils/errorHandler';
import { useIsComponentMounted } from 'global/hooks/useIsComponentMounted';
import { IOpportunity } from 'global/interfaces/opportunity';
import { getMyApplications } from 'services/opportunityService';
import { filterApplicationStatus } from 'components/opportunities/myApplications/myOppFilters';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { format } from 'date-fns';

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary[50],
  borderRadius: '12px',
  padding: '16px 20px 16px 20px',
  position: 'relative',
}));

const StyledTotalBox = styled(StyledBox)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}));

const StyledTitleTypography = styled(Typography)(() => ({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default function ItemLatestProposals() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [latestOpportunities, setLatestOpportunities] = useState<IOpportunity[]>([]);
  const isComponentMounted = useIsComponentMounted();

  const [numProposals, setNumProposals] = useState<number>(0);
  const [numTracked, setNumTracked] = useState<number>(0);
  const [numViewed, setNumViewed] = useState<number>(0);
  const [numClicked, setNumClicked] = useState<number>(0);

  useEffect(() => {
    loadOpportunities();
  }, []);

  const loadOpportunities = () => {
    if (isComponentMounted.current) {
      setLoading(true);
      setLatestOpportunities([]);
    }

    getMyApplications()
      .then(res => {
        if (isComponentMounted.current) {
          setNumProposals(res.length);
          setNumTracked(res.length - filterApplicationStatus('NotTrackable', res).length);
          setNumViewed(filterApplicationStatus('ViewedOnly', res).length);
          setNumClicked(filterApplicationStatus('Clicked', res).length);
          if (res.length > 5) res = res.slice(0, 5);
          setLatestOpportunities(res);
          setLoading(false);
        }
      })
      .catch(showError)
      .finally(() => setLoading(false));
  };
  return (
    <MainSectionBox>
      <JustifiedBox>
        <Stack direction="row" spacing={1.5}>
          <Typography variant="subtitle1" fontWeight={600}>
            Proposals in the Last 30 days
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            sx={{ color: theme.palette.primary.dark, mr: '-6px', padding: 1 }}
            endIcon={<ArrowForward />}
            onClick={() => navigate(`/gigs/my-proposals`)}
          >
            View all
          </Button>
        </Stack>
      </JustifiedBox>
      {loading && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <Grow in={true} timeout={500}>
          <Box mb={2} mt={3}>
            <StyledTotalBox>
              <JustifiedBox>
                <Typography variant="subtitle1">Total Proposals</Typography>
                <Typography variant="h6">{numProposals}</Typography>
              </JustifiedBox>{' '}
            </StyledTotalBox>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={12}>
                <StyledBox>
                  <JustifiedBox>
                    <Typography variant="subtitle1">Direct Proposals</Typography>
                    <Typography variant="h6">{numTracked}</Typography>
                  </JustifiedBox>{' '}
                </StyledBox>
              </Grid>

              <Grid item xs={6}>
                <StyledBox alignContent={'center'}>
                  <Typography variant="h6" textAlign={'center'}>
                    {numTracked > 0 ? <>{(((numViewed + numClicked) / numTracked) * 100).toFixed(1)}%</> : <> 0%</>}
                  </Typography>
                  <Typography variant="subtitle2" textAlign={'center'} mt={1}>
                    Viewed
                  </Typography>
                </StyledBox>
              </Grid>
              <Grid item xs={6}>
                <StyledBox alignContent={'center'}>
                  <Typography variant="h6" textAlign={'center'}>
                    {numTracked > 0 ? <> {((numClicked / numTracked) * 100).toFixed(1)}%</> : <> 0%</>}
                  </Typography>
                  <Typography variant="subtitle2" textAlign={'center'} mt={1}>
                    Clicked
                  </Typography>
                </StyledBox>
              </Grid>
            </Grid>

            <Box mb={2} pl={0.5} pr={0.5}>
              <Typography variant="subtitle1" fontWeight={600} mt={3}>
                Latest Proposals Sent
              </Typography>

              {latestOpportunities.length > 0 &&
                latestOpportunities.map((opportunity, index: number) => (
                  <Stack key={index} direction="column" spacing={1.5} mt={3.0}>
                    <JustifiedBox>
                      <StyledTitleTypography
                        variant="subtitle2"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => window.open(`/gigs/${opportunity.id}`, '_blank')}
                        marginRight={1}
                      >
                        {opportunity.title}
                      </StyledTitleTypography>

                      <Stack direction={'row'} gap={0.5} minWidth={'110px'} mt={0.5} alignItems={'center'}>
                        <CalendarMonthOutlinedIcon sx={{ stroke: theme.palette.grey[300] }} />
                        <Typography variant="body2" color={theme.palette.grey[500]}>
                          {opportunity.applicants &&
                            opportunity.applicants[0].appliedDate &&
                            format(new Date(opportunity.applicants[0].appliedDate), 'yyyy-MM-dd')}
                        </Typography>
                      </Stack>
                    </JustifiedBox>
                  </Stack>
                ))}
              {latestOpportunities.length == 0 && (
                <Typography variant="body2" mt={3.0}>
                  You haven&apos;t sent any proposals yet.
                </Typography>
              )}
            </Box>
          </Box>
        </Grow>
      )}
    </MainSectionBox>
  );
}
