import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DetailContainer } from 'components/terms/Containers';
import { useTitle } from 'utils/router';

import HeroSection from 'components/info/Hero';
import personIm from 'assets/images/lps/personimage5.webp';
import SaleemIm from 'assets/images/user/Saleem.png';
import JonathanIm from 'assets/images/user/Jonathan.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

const StyledIconButton = styled(IconButton)`
  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.grey[700]};
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default function About(): JSX.Element {
  useTitle('About Us');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <HeroSection
        title={'About us'}
        subtext="Freelancing should be about work—not wasting time searching for it."
        minHeight={isMobileScreen ? '260px' : '470px'}
        personImage={personIm}
      />
      <Box bgcolor="#F8F7F4">
        <Container maxWidth="lg">
          <DetailContainer>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Finding freelance gigs shouldn’t feel like a part-time job. Yet, most freelancers spend hours hunting for
              quality gigs, only to get buried under hundreds of other applicants—or worse, end up sifting through
              low-paying gigs and vague briefs.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              That’s where Shoutt comes in. We do the heavy lifting, so you can focus on what actually matters—landing
              great projects and growing your business.
            </Typography>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={'8px'}>
              How it works
            </Typography>
            <ol style={{ margin: '0px' }}>
              <li>
                <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                  <b>We Find the Gigs, You Focus on the Work: </b> We pull 1,500+ fresh freelance gigs from 30+ trusted
                  sources every month, all in one place.
                </Typography>
              </li>
              <li style={{ marginTop: '8px' }}>
                <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                  <b>Get to the Best Gigs First: </b> Every gig is less than 7 days old, so you can pitch early and
                  increase your chances of getting hired.
                </Typography>
              </li>
              <li style={{ marginTop: '8px' }}>
                <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                  <b>No More Junk Gigs: </b> We filter out lowball offers, vague briefs, and outdated listings, so you
                  only see real opportunities worth your time.
                </Typography>
              </li>
              <li style={{ marginTop: '8px' }}>
                <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                  <b>Work Smarter, Not Harder: </b> Instead of juggling multiple job boards and websites, Shoutt
                  delivers a curated list of high-quality gigs—saving you hours every week.
                </Typography>
              </li>
            </ol>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={1.5}>
              Our Mission
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Freelancers deserve better access to work, with less hassle. Shoutt is here to cut through the noise,
              making it easier to find quality freelance gigs—without the endless scrolling, guesswork, or wasted time.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              The best gigs are out there. We’ll help you find them.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={1} mb={isMobileScreen ? 0 : 1}>
              Our Founders
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6} lg={5}>
                <Stack direction={'row'} spacing={2} mt={isMobileScreen ? 2 : undefined} alignItems={'center'}>
                  <Avatar
                    sx={{
                      width: isMobileScreen ? '90px' : '96px',
                      height: isMobileScreen ? '90px' : '96px',

                      mt: isMobileScreen ? 1.5 : undefined,
                      borderRadius: '12px',
                    }}
                    src={SaleemIm}
                    variant="square"
                  ></Avatar>
                  <Stack direction={'column'} pt={1}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color={'#101828'}
                      fontSize={isMobileScreen ? '20px' : undefined}
                    >
                      Saleem Yaqub
                    </Typography>
                    <Typography
                      variant={isMobileScreen ? 'body2' : 'body1'}
                      fontWeight={400}
                      color={'#475467'}
                      mt={0.5}
                    >
                      Co-Founder & CEO
                    </Typography>
                    <Link to="https://www.linkedin.com/in/saleemyaqub/" target="_blank">
                      <StyledIconButton sx={{ padding: 0 }}>
                        <LinkedInIcon
                          sx={{
                            height: isMobileScreen ? '24px' : '28px',
                            width: isMobileScreen ? '24px' : '28px',
                            mt: 0.5,
                            cursor: 'pointer',
                          }}
                        ></LinkedInIcon>
                      </StyledIconButton>
                    </Link>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Stack direction={'row'} spacing={2} mt={isMobileScreen ? 2 : undefined} alignItems={'center'}>
                  <Avatar
                    sx={{
                      width: isMobileScreen ? '90px' : '96px',
                      height: isMobileScreen ? '90px' : '96px',
                      mt: isMobileScreen ? 1.5 : undefined,
                      borderRadius: '12px',
                    }}
                    src={JonathanIm}
                    variant="square"
                  ></Avatar>
                  <Stack direction={'column'} pt={1}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color={'#101828'}
                      fontSize={isMobileScreen ? '20px' : undefined}
                    >
                      Jonathan Eadie
                    </Typography>
                    <Typography
                      variant={isMobileScreen ? 'body2' : 'body1'}
                      fontWeight={400}
                      color={'#475467'}
                      mt={0.5}
                    >
                      Co-Founder & CTO
                    </Typography>
                    <Link to="https://www.linkedin.com/in/jonathan-eadie/" target="_blank">
                      <StyledIconButton sx={{ padding: 0 }}>
                        <LinkedInIcon
                          sx={{
                            height: isMobileScreen ? '24px' : '28px',
                            width: isMobileScreen ? '24px' : '28px',
                            mt: 0.5,
                            cursor: 'pointer',
                          }}
                        ></LinkedInIcon>
                      </StyledIconButton>
                    </Link>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </DetailContainer>
        </Container>
      </Box>
    </>
  );
}
