import { INewOpportunityForm, OpportunityLocationType, RemoteType } from 'global/interfaces/opportunity';
import { SchemaOf, array, boolean, mixed, object, string } from 'yup';

export const opportunityValidationSchema = (shouldValidateToken: boolean): SchemaOf<INewOpportunityForm> => {
  const baseSchema = object({
    name: string()
      .required('Name is required')
      .trim()
      .min(2, 'Minimum of 2 characters needed')
      .max(30, 'Maximum of 30 characters allowed'),
    email: string()
      .required('Email is required')
      .trim()
      .max(150, 'Maximum of 150 characters allowed')
      .email('Invalid email address'),
    description: string()
      .required('Project details are required')
      .min(20, 'Minimum of 20 characters needed')
      .max(10000, 'Maximum of 10000 characters allowed'),
    title: string()
      .required('Title is required')
      .min(2, 'Minimum of 2 characters needed')
      .max(100, 'Maximum of 100 characters allowed'),
    budget: string()
      .notRequired()
      .when('isExternal', {
        is: false,
        then: string().required('Budget is required'),
      }),

    userId: string().notRequired(),
    isExternal: boolean().required(),
    applyInstructions: string()
      .notRequired()
      .when('isExternal', {
        is: true,
        then: string().nullable().required('Enter apply instructions'),
      }),
    categories: array().required().min(1, 'You must select at least 1 category'),
    locationType: mixed<OpportunityLocationType>().required('You must indicate if this opportunity is fully remote'),
    location: string().notRequired(),
    city: string()
      .notRequired()
      .when('locationType', {
        is: (val: OpportunityLocationType) =>
          [OpportunityLocationType.Local, OpportunityLocationType.Hybrid].includes(val),
        then: string().required('Enter the location'),
      }),
    isFree: boolean().notRequired(),
    isAssisted: boolean().notRequired(),
    assistedEmail: string()
      .notRequired()
      .when('isAssisted', {
        is: true,
        then: string().nullable().required('Enter email address'),
      }),
    remoteType: mixed<RemoteType>()
      .notRequired()
      .when('locationType', {
        is: OpportunityLocationType.Remote,
        then: mixed<RemoteType>().required('Remote type is required'),
      }),
    selectedCountries: array()
      .notRequired()
      .when('remoteType', {
        is: RemoteType.SelectedCountries,
        then: array().required().min(1, 'You must select at least one country'),
      }),
    additionalLocationInfo: string().notRequired().max(255, 'Addional Location Details must be at most 255 characters'),
    internalNotes: string().notRequired(),
  });

  if (shouldValidateToken) {
    return baseSchema.shape({
      token: string().nullable().required('You must complete the reCAPTCHA'),
    });
  }

  return baseSchema.shape({
    token: string().notRequired(),
  });
};
