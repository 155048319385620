import { Box, DialogContent, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogTitle } from 'components/common/Dialog';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch } from 'react';

interface WinClientProps {
  onClose: () => void;
  setIsFinalScreen: Dispatch<boolean>;
}

export const PriceFeatureTypography = styled(Typography)(() => ({
  lineHeight: '140%',
  fontSize: '16px',
  color: '#101828',
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '0px 24px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px !important',
    minHeight: 'calc(100svh - 200px)',
  },
}));

export function WinClientDetails({ onClose, setIsFinalScreen }: WinClientProps) {
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h5" color={theme.palette.grey[900]}>
          Spend less time searching, more time earning
        </Typography>
        <IconButton onClick={onClose} sx={{ marginRight: '-12px' }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: '28px',
                borderRadius: '12px',
                background: 'linear-gradient(180deg, #F1F4FE 0%, #EAEEFA 100%)',

                paddingBottom: '20px',
              }}
            >
              <PriceFeatureTypography variant="body1">
                Shoutt gives you instant access to 1,500+ curated gigs every month. All our gigs are available on other
                sites, so you could find them yourself but here is why you shouldn’t:
              </PriceFeatureTypography>
              <PriceFeatureTypography variant="body1" mt={3}>
                ➡️ The average freelancer spends 3-6 hours a week looking for gigs. Let’s assume you could save 2 hours
                a week using Shoutt. That’s 8 hours a month you’re not working on paid projects that you could be.
              </PriceFeatureTypography>
              <PriceFeatureTypography variant="body1" mt={1}>
                ➡️ If your time is worth {localCurrencySymbol}50/hr, that’s {localCurrencySymbol}400 of potential
                earnings lost.
              </PriceFeatureTypography>
              <PriceFeatureTypography variant="body1" mt={1}>
                ➡️ Meanwhile, Shoutt could automate that for {localCurrencySymbol}
                {price} per month, freeing you up for actual income-generating tasks.
              </PriceFeatureTypography>
              <PriceFeatureTypography variant="body1" mt={3}>
                As a freelancer, time is your most valuable asset. We help you spend it wisely.
              </PriceFeatureTypography>
            </Box>
          </Grid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={onClose}>
          Cancel
        </RoundButton>
        <RoundButton fullWidth={isMobileScreen} variant="contained" onClick={() => setIsFinalScreen(true)}>
          Continue
        </RoundButton>
      </StyledDialogActions>
    </>
  );
}
