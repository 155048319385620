import { FaqOption } from 'global/interfaces/faq';
import { Link } from 'react-router-dom';

export const billingFaqOptions: FaqOption[] = [
  {
    id: '01',
    question: 'How do I get started on Shoutt?',
    answer: (
      <>
        Getting started with Shoutt is simple:
        <ol style={{ marginTop: '16px', marginBottom: '16px' }}>
          <li>
            <b>Create a Free Account</b> – Sign up and start browsing up to 10% of available gigs to see what’s out
            there.
          </li>
          <li>
            <b>Upgrade to Premium</b> – Upgrade to unlock 50% of all gigs, including high-quality gigs with direct
            application links.
          </li>
          <li>
            <b>Get Verified for Access to Direct Gigs</b> – To access 100% of gigs, including those with direct email
            applications, complete your profile and submit it for verification. This step ensures only qualified
            freelancers can respond, preventing job posters from getting spammed.
          </li>
        </ol>
        Once you’re verified, you’ll have access to every gig on Shoutt, meaning less time searching, more time working.
      </>
    ),
  },

  {
    id: '02',
    question: 'Where do you get the gigs from?',
    answer:
      'We spend hundreds of hours sourcing 1,500+ high-quality freelance gigs every month from 40+ trusted platforms, adding them to one centralised place on Shoutt. Gigs are added throughout the day M-F, so you always get fresh, relevant opportunities—never older than 7 days. We’re also working on building AI tools to increase the volume and diversity of gigs.',
  },
  {
    id: '03',
    question: 'How do you assess gig quality?',
    answer: (
      <>
        We carefully vet every gig to ensure you’re only seeing real, high-quality freelance opportunities—not lowball
        offers, vague briefs, or outdated listings. Our process includes:
        <ul style={{ marginTop: '16px', marginBottom: '16px' }}>
          <li>
            Source Credibility – We pull gigs from trusted platforms, job boards, social media, and direct business
            listings, avoiding spammy or low-value listings.
          </li>
          <li>
            Budget & Fair Pay – We screen out gigs with unrealistic budgets or unclear payment terms, so you don’t waste
            time on underpaid work.
          </li>
          <li>
            Clear Briefs & Legitimate Clients – Gigs must include detailed job descriptions, clear expectations, and
            real contact details to ensure they’re worth your time.
          </li>
          <li>
            Freshness – We only list gigs that are less than 7 days old, so you can pitch early and increase your
            chances of landing work.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: '04',
    question: 'How do the real-time alerts work?',
    answer: (
      <>
        Our real-time alerts are very simple. Just set-up your filters on the home page and you will get instant Slack
        alerts in our Slack community anytime a gig is added that matches your filters. Learn more{' '}
        <Link to={'https://help.shoutt.co/en/articles/4032002'} target="_blank" style={{ color: '#2652db' }}>
          here
        </Link>
        {'. '}
      </>
    ),
  },
  {
    id: '05',
    question: 'Can I cancel anytime?',
    answer: (
      <>
        Yes! You can{' '}
        <Link to={'https://help.shoutt.co/en/articles/3850050'} target="_blank" style={{ color: '#2652db' }}>
          cancel anytime
        </Link>{' '}
        from your account settings page.
      </>
    ),
  },

  {
    id: '06',
    question: 'Why is vetting required, and what is the purpose of profiles?',
    answer: (
      <>
        Shoutt is all about quality over quantity.
        <ol style={{ marginTop: '16px', marginBottom: '16px' }}>
          <li>
            For Premium-Direct gigs, we vet freelancers to ensure that we only send skilled, professional talent to the
            original source, rather than a flood of unqualified applicants.
          </li>
          <li>
            Your profile is also a public listing on our talent network—where prospective clients can discover and
            contact you directly (without needing to sign up).
          </li>
          <li>Your profile is used to match you with the most relevant gigs.</li>
        </ol>
      </>
    ),
  },
  {
    id: '07',
    question: 'Where is Shoutt based?',
    answer:
      'Shoutt is a UK-based startup with a remote team across the UK, US, and Europe. We are supported by Innovate UK (a government-backed accelerator) and Microsoft for Startups. Our registered office address is 86-90 Paul Street, London, EC2A 4NE. ',
  },
];
