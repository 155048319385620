import {
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { IApplyOpportunity, ApplyStageType, IOpportunity, OpportunityType } from 'global/interfaces/opportunity';
import { RoundButton } from 'components/common/Button/RoundButton';

import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { applyToShoutt, messagePoster, profileNotApproved } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useNavigate } from 'react-router-dom';
import { VettingStatus } from 'global/enums/vettingStatus';
import { useFormik } from 'formik';
import { array, boolean, object, string } from 'yup';
import { pushToDataLayer } from 'utils/tagHelper';
import { appliedOpportunity } from 'global/constants';
import { UserAvatar } from 'components/common/UserAvatar';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ApplyAttachfiles from './ApplyAttachFiles';
import { StyledLink } from 'components/common/Link/StyledLink';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface IApplyShouttOpportunity {
  onClose: () => void;
  open: boolean;
  opportunity: IOpportunity | null;
  updateApplyStageOnClose: (newStage: ApplyStageType) => void;
}
const ApplicationDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '30px 24px 10px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  borderBottomStyle: 'none',

  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px !important',
  },
}));

const StyledUserAvatar = styled(
  UserAvatar,
  {},
)(() => ({
  height: '60px',
  width: '60px',
  borderRadius: '12px',
}));

export default function ApplyShouttOpportunity(props: IApplyShouttOpportunity): JSX.Element {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const authContext = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (props.open && props.opportunity != null && authContext.user?.vettingStatus != VettingStatus.Approved) {
      profileNotApproved(props.opportunity.id);
    } else if (props.open && props.opportunity != null && authContext.user?.vettingStatus == VettingStatus.Approved) {
      messagePoster(props.opportunity.id);
    }
    setSuccess(false);
    form.setFieldValue('message', '');
    form.setFieldValue('subject', '');
  }, [props.opportunity, props.open]);

  let baseSchema = object({
    message: string().max(2000, 'Message must be at most 2000 characters').required('You must enter a message'),
    files: array().max(5, 'A maximum of 5 files can be attached').optional(),
    isUploading: boolean().required().isFalse('Please wait for files to finish uploading'),
  });

  if (props.opportunity?.type == OpportunityType.Shoutt) {
    baseSchema = baseSchema.shape({
      subject: string().notRequired(),
    });
  } else {
    baseSchema = baseSchema.shape({
      subject: string().max(200, 'Subject must be at most 200 characters').required('You must enter a subject'),
    });
  }

  const form = useFormik<IApplyOpportunity>({
    initialValues: {
      message: '',
      subject: '',
      files: [],
      isUploading: false,
      shareProfile: false,
    },
    validationSchema: baseSchema,
    onSubmit: values => {
      setLoading(true);

      applyToShoutt({
        opportunityId: props.opportunity?.id ?? '',
        message: values.message,
        subject: values.subject,
        files: values.files,
        shareProfile: values.shareProfile,
      })
        .then(() => {
          setLoading(false);
          setSuccess(true);
          pushToDataLayer(appliedOpportunity, {
            transaction_id: props.opportunity?.id,
          });
        })
        .catch(e => {
          setLoading(false);
          showError(e);
        });
    },
  });

  const updateApplyOnClose = () => {
    props.updateApplyStageOnClose(ApplyStageType.Applied);
  };

  return (
    <>
      {authContext.user?.vettingStatus != VettingStatus.Approved && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              {authContext.user?.vettingStatus != VettingStatus.Rejected ? 'Get listed' : 'Profile rejected'}
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          {authContext.user?.vettingStatus === VettingStatus.NotSubmitted && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  We require verification before giving access to direct email applications to prevent spam and ensure
                  only qualified freelancers can respond. This protects businesses from inbox overload. Create your
                  profile now and submit for approval to get verified.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Cancel
                </RoundButton>
                <RoundButton
                  variant="contained"
                  fullWidth={isMobileScreen}
                  onClick={() => navigate(`/users/${authContext.user?.id}/edit?apply=true`)}
                >
                  Get Verified
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {authContext.user?.vettingStatus === VettingStatus.Rejected && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  Your profile was rejected so you are not able to contact this gig.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Close
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {authContext.user?.vettingStatus === VettingStatus.InProgress && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  You have submitted your profile for approval and it is currently being reviewed. You will be able to
                  respond to this gig when your profile is approved.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Close
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {authContext.user?.vettingStatus === VettingStatus.UpdatesRequired && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  To contact this gig you must be Shoutt verified. This ensures that only quality vetted freelancers can
                  respond to this gig. You have submitted for approval and have been requested to make the following
                  updates:
                </Typography>
                <Typography variant="body1" mt={2} fontStyle={'italic'}>
                  {authContext.user?.feedback}
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Cancel
                </RoundButton>
                <RoundButton
                  variant="contained"
                  fullWidth={isMobileScreen}
                  onClick={() => navigate(`/users/${authContext.user?.id}/edit`)}
                >
                  Update Profile
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
        </>
      )}
      {props.opportunity != null &&
        authContext.user?.vettingStatus == VettingStatus.Approved &&
        !props.opportunity.applied &&
        !success && (
          <>
            <StyledDialogTitle>
              <Typography variant="h6" color={theme.palette.grey[900]}>
                {props.opportunity.title}
              </Typography>
              <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
                <CloseIcon />
              </IconButton>
            </StyledDialogTitle>
            <ApplicationDialogContent dividers>
              <Grid container spacing={3} justifyContent="center">
                {props.opportunity.type == OpportunityType.Shoutt && (
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <StyledUserAvatar
                        userId={authContext.user?.id}
                        displayName={authContext.user.firstName + ' ' + authContext.user.lastName}
                      />
                      <Stack
                        direction="column"
                        spacing={0.5}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => window.open(`/users/${authContext.user?.id}/`, '_blank')}
                      >
                        <Typography
                          variant={'subtitle1'}
                          fontWeight={600}
                          fontSize={'18px'}
                          alignItems={'center'}
                          component={'div'}
                          display={'flex'}
                        >
                          <span>{authContext.user.firstName + ' ' + authContext.user.lastName}</span>
                          <OpenInNewOutlinedIcon sx={{ width: '18px', height: '18px', ml: 0.75 }} />
                        </Typography>

                        <Typography variant="body1" color={'#4e61d9'}>
                          {authContext.user.title}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                )}
                {props.opportunity.type == OpportunityType.External && props.opportunity.isAssisted && (
                  <Grid item xs={12}>
                    <Typography variant={'subtitle1'} fontWeight={700} mb={0.5} fontSize={'18px'}>
                      Subject
                    </Typography>
                    <Typography variant="body2" mb={2.5}>
                      Please enter a subject that will be used in our email to the client. Use a subject that will both
                      stand out and show relevance!
                    </Typography>
                    <FormGroup>
                      <FormControl>
                        <TextField
                          name="subject"
                          fullWidth
                          value={form.values.subject}
                          onChange={form.handleChange}
                          error={form.touched.subject !== undefined && Boolean(form.errors.subject)}
                          helperText={form.touched.subject !== undefined ? form.errors.subject : ''}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant={'subtitle1'} fontWeight={700} mb={0.5} fontSize={'18px'}>
                    Cover note
                  </Typography>
                  <Typography variant="body2" mb={2.5}>
                    This will be emailed to the client along with any attachments you include. Use this to highlight why
                    you’re a great fit!
                  </Typography>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name="message"
                        multiline
                        rows={isMobileScreen ? 10 : 6}
                        fullWidth
                        value={form.values.message}
                        onChange={form.handleChange}
                        error={form.touched.message !== undefined && Boolean(form.errors.message)}
                        helperText={form.touched.message !== undefined ? form.errors.message : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'subtitle1'} fontWeight={700} mb={0.5} fontSize={'18px'}>
                    Share Profile
                  </Typography>
                  <Typography variant="body2">
                    Optional: Include a link to your{' '}
                    <StyledLink sx={{ display: 'contents' }} to={`/users/${authContext.user.id}/edit`} target="_blank">
                      Shoutt profile <OpenInNewIcon sx={{ fontSize: '16px', verticalAlign: 'text-bottom' }} />
                    </StyledLink>{' '}
                    at the end of your cover note
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={form.handleChange}
                        name="shareProfile"
                        checked={form.values.shareProfile}
                        size="medium"
                      />
                    }
                    label=""
                  />
                </Grid>

                <Grid item xs={12}>
                  <ApplyAttachfiles
                    form={form}
                    oppId={props.opportunity.id}
                    helperText={
                      (form.touched.files !== undefined && typeof form.errors.files === 'string'
                        ? form.errors.files
                        : '') ||
                      (form.touched.files !== undefined && form.errors.isUploading ? form.errors.isUploading : '')
                    }
                  />
                </Grid>
              </Grid>
            </ApplicationDialogContent>
            <StyledDialogActions>
              <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
                Cancel
              </RoundButton>
              <RoundButton
                loading={loading}
                disabled={loading}
                variant="contained"
                type="button"
                fullWidth={isMobileScreen}
                onClick={() => form.handleSubmit()}
              >
                Submit Proposal
              </RoundButton>
            </StyledDialogActions>
          </>
        )}
      {props.opportunity != null &&
        authContext.user?.vettingStatus == VettingStatus.Approved &&
        !props.opportunity.applied &&
        success && (
          <>
            <StyledDialogTitle>
              <Typography variant="h6" color={theme.palette.grey[900]}>
                Proposal Sent
              </Typography>
              <IconButton onClick={updateApplyOnClose} sx={{ marginRight: '-12px' }}>
                <CloseIcon />
              </IconButton>
            </StyledDialogTitle>
            <StyledDialogContent dividers>
              <Typography variant="body1">
                Your proposal has been successfully submitted. The client will be in touch if they want to discuss
                further.
              </Typography>
            </StyledDialogContent>
            <StyledDialogActions>
              <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={updateApplyOnClose}>
                Close
              </RoundButton>
            </StyledDialogActions>
          </>
        )}
      {props.opportunity != null && props.opportunity.applied && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              Proposal Already Sent
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <Typography variant="body1">
              You have already sent a proposal to this gig &quot;{props.opportunity.title}&quot;. The client will be in
              touch if they want to discuss further.
            </Typography>
          </StyledDialogContent>
          <StyledDialogActions>
            <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.onClose}>
              Close
            </RoundButton>
          </StyledDialogActions>
        </>
      )}
    </>
  );
}
