import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { DependencyList, useContext, useEffect } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';

export const redirectToLogin = (navigate: NavigateFunction, location: Location) => {
  navigate('/login', { state: { redirectTo: location } });
};

export const redirectToSignUp = (navigate: NavigateFunction, location: Location) => {
  navigate('/signup/details?accountType=0', { state: { redirectTo: location } });
};

export const redirectToSignUpSeller = (navigate: NavigateFunction, location: Location) => {
  navigate('/signup/details?accountType=1', { state: { redirectTo: location } });
};

export const getRedirectUrl = (location: Location) => {
  if (location.state) {
    const { redirectTo } = location.state;
    if (redirectTo.state?.sellerId) {
      const url = redirectTo.state?.opportunityId
        ? `/users/${redirectTo.state?.sellerId}?opportunityId=${redirectTo.state?.opportunityId}`
        : `/users/${redirectTo.state?.sellerId}`;
      return url;
    } else if (redirectTo.state?.opportunityId) {
      return `/gigs/${redirectTo.state?.opportunityId}`;
    }
    return `${redirectTo.pathname}${redirectTo.search}`;
  }
  return '/';
};

export const useTitle = (title?: string, deps: DependencyList = []) => {
  const { titlePrefix } = useContext(GlobalStateContext);
  useEffect(() => {
    const prefix = titlePrefix !== '' ? `${titlePrefix} ` : titlePrefix;
    if (title?.startsWith('Shoutt')) {
      document.title = `${prefix}${title}`;
    } else {
      const subTitle = title ? `${title} | ` : '';
      document.title = `${prefix}${subTitle}Shoutt`;
    }
  }, [...deps, titlePrefix]);
};
