import { Box, Stack, TextField } from '@mui/material';
import FormSelect from 'components/common/Select/FormSelect';
import { format } from 'date-fns';
import { FormikProps } from 'formik';
import { SelectItem } from 'global/interfaces/selects';
import { DowngradeReason, IDowngradeSubscription, UserSubscription } from 'global/interfaces/userSubscription';

export default function CancelContent() {
  return (
    <Stack gap={2}>
      <Box>
        We understand that things can change, and you’re free to downgrade your account at any time. However, before you
        make your decision, please keep in mind that your profile will no longer be boosted on our talent network, thus
        reducing your chance of being contacted by prospective clients.
      </Box>
      <Box>
        If you’re not quite ready to leave but need a break, you can pause your plan at 50% discount for as long as
        needed. Your profile will remain boosted on our talent network but you will not have access to Premium gigs.
      </Box>
      <Box>Would you like to pause your subscription now?</Box>
    </Stack>
  );
}

export const cancelContentHeader = 'Thinking of downgrading to a free account?';

interface ICancelReasonProps {
  form: FormikProps<IDowngradeSubscription>;
  subscription: UserSubscription | undefined;
}

export function CancelReason({ form, subscription }: ICancelReasonProps) {
  return (
    <>
      Why do you want to downgrade?
      <Box>
        <FormSelect
          label="Reason"
          name="reason"
          items={ReasonSelectList}
          onChange={form.handleChange}
          error={form.touched.reason && Boolean(form.errors.reason)}
          helpertext={form.touched.reason ? form.errors.reason : ''}
        />
      </Box>
      {form.values.reason === DowngradeReason.other && (
        <Box>
          <TextField
            fullWidth
            name="comment"
            value={form.values.comment}
            onChange={form.handleChange}
            variant={'outlined'}
            multiline
            rows={6}
            error={form.touched.comment && Boolean(form.errors.comment)}
            helperText={form.touched.comment ? form.errors.comment : ''}
          />
        </Box>
      )}
      By downgrading your premium plan will end on{' '}
      {subscription?.subscriptionCurrentPeriodEnd &&
        format(new Date(subscription.subscriptionCurrentPeriodEnd), 'MMMM d, yyyy')}
      &nbsp;and your profile will be deleted. You can still use premium features until that date. Would you like to
      downgrade now?
    </>
  );
}

const ReasonSelectList: SelectItem[] = [
  {
    id: DowngradeReason.low_quality,
    label: 'Not enough opportunities for my skillset',
  },
  {
    id: DowngradeReason.unused,
    label: "I have enough work now so don't need this",
  },
  {
    id: DowngradeReason.switched_service,
    label: 'I can find the external opportunities myself',
  },
  {
    id: DowngradeReason.too_expensive,
    label: 'Too expensive',
  },
  {
    id: DowngradeReason.customer_service,
    label: 'Customer service has been poor',
  },
  {
    id: DowngradeReason.other,
    label: 'Other',
  },
];
