import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTitle } from 'utils/router';

import OpportunitySubscribe from 'components/opportunities/newsletter/OpportunitySubscribe';
import ViewOpportunitiesComponent from 'components/opportunities/ViewOpportunitiesComponent';
import HeroSection, { IReview, IUsp } from 'components/info/Hero';
import { ClockIcon } from 'components/icon/ClockIcon';
import { BriefIcon } from 'components/icon/BriefIcon';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';

const ViewOpportunities = () => {
  useTitle('Shoutt - All the best freelance gigs in one place');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const review: IReview = {
    name: '',
    roleTitle: 'More gigs coming soon!',
    reviewText: 'Shoutt is a AI powered business development tool for serious freelancers',
  };

  const usps: IUsp[] = [
    {
      icon: (
        <ExclusiveIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Search less, earn more',
      text: 'We scan marketplaces, social media and job boards many times a day so you can pitch early.',
    },
    {
      icon: (
        <ClockIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Save 3 - 6 hours per week',
      text: 'Cut down on time spent scrolling and filtering junk out. Quality gigs, all less than 7 days old.',
    },
    {
      icon: (
        <BriefIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: '40+ sources in one',
      text: 'Discover new gigs that you could miss. We scan 40+ sources with more being added regularly.',
    },
  ];
  return (
    <>
      <Box sx={{ backgroundColor: '#F8F7F4', minHeight: '1000px' }}>
        <HeroSection title={'1500+ hand-picked freelance gigs every month in one place'} review={review} usps={usps} />
        <OpportunitySubscribe />
        <Container maxWidth="lg">
          <ViewOpportunitiesComponent />
        </Container>
      </Box>
    </>
  );
};

export default ViewOpportunities;
