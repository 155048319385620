import { AuthContext } from 'contexts/AuthContext';
import { useContext, useState } from 'react';
import { createProductCheckout } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import IApiError from 'global/interfaces/api';
import PricingTable from 'components/common/Subscription/PricingTable';
import { StyledCTAButtton } from 'components/common/Button/CTAs';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { pushToDataLayer } from 'utils/tagHelper';
import { beginCheckoutEvent } from 'global/constants';
import { UnpauseSubscription } from './unpauseSubscription';

export default function InactiveSubscription() {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);
  const isPaused = authContext.user?.isPaused === true;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleCheckout = () => {
    setLoading(true);
    pushToDataLayer(beginCheckoutEvent, {
      transaction_id: authContext.user?.id,
    });
    createProductCheckout()
      .then((res: string) => {
        window.location.href = res;
      })
      .catch((e: IApiError) => {
        showError(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Typography variant={'body1'} fontSize={{ xs: '16px', md: '18px' }} mt={isMobileScreen ? 2 : 4}>
        You are currently on the free plan. Plans run month-to-month and can be upgraded or downgraded anytime. For more
        info on billing please scroll down to the FAQs.
      </Typography>
      <Box mt={isMobileScreen ? 3 : 6}>
        <PricingTable
          isSeller={authContext.user?.isSellingServices ?? false}
          currencySybmol={localCurrencySymbol}
          premPrice={price}
          freeBgColor="#e3c094"
          freeCTA={
            <>
              <StyledCTAButtton variant="contained" disabled fullWidth>
                Your Plan
              </StyledCTAButtton>
            </>
          }
          premCTA={
            <>
              {!isPaused ? (
                <StyledCTAButtton variant="contained" loading={loading} onClick={handleCheckout} fullWidth>
                  Upgrade Now
                </StyledCTAButtton>
              ) : (
                <UnpauseSubscription loading={loading} setLoading={setLoading} />
              )}
            </>
          }
        />
      </Box>
    </>
  );
}
