import { IFileMetadata, IStorageFile } from './file';
import { IPagingFilter } from './ordering';

export interface INewOpportunityForm {
  name: string;
  email: string;
  title: string;
  budget?: string;
  description: string;
  token?: string | null;
  userId?: string;
  isExternal: boolean;
  applyInstructions?: string;
  categories: string[];
  locationType?: OpportunityLocationType | null;
  location?: string;
  city?: string;
  isFree?: boolean;
  isAssisted?: boolean;
  assistedEmail?: string;
  remoteType?: RemoteType | null;
  selectedCountries?: string[];
  additionalLocationInfo?: string;
  internalNotes?: string;
}

export enum OpportunityType {
  Shoutt = 'Shoutt',
  External = 'External',
}

export enum OpportunityLocationType {
  Remote = 'Remote',
  Local = 'Local',
  Hybrid = 'Hybrid',
}

export enum ApplyStageType {
  Viewed = 0,
  PayWalled = 1,
  ProfileNotApproved = 2,
  MessagePoster = 3,
  Applied = 4,
  NotInterested = 5,
}

export interface IApplicant {
  userId: string;
  name: string;
  title: string;
  message: string;
  files: IStorageFile[];
  appliedDate?: string;
  firstOpenedOn?: string;
  firstClickedOn?: string;
}

export interface IOpportunity {
  id: string;
  name: string;
  title: string;
  budget?: string;
  description: string;
  userId?: string;
  type: OpportunityType;
  createdDate?: string;
  agoDesc?: string;
  categories: string[];
  locationType?: OpportunityLocationType;
  location?: string;
  city?: string;
  applied: boolean;
  status: OpportunityStatus;
  email?: string;
  applyInstructions?: string;
  applyInstructionsType: ApplyInstructionsType;
  isFree: boolean;
  applicants?: IApplicant[];
  isAssisted: boolean;
  applicantCount?: number;
  assistedEmail?: string;
  tokenViews?: number;
  lastTokenViewDate?: string;
  accessToken?: string;
  applyStage?: ApplyStageType;
  isLive: boolean;
  remoteType?: RemoteType;
  selectedCountries?: string[];
  additionalLocationInfo?: string;
  source?: string;
  internalNotes?: string;
}

export interface IApplyShouttOpp {
  opportunityId: string;
  message: string;
  subject?: string;
  files?: IFileMetadata[];
  shareProfile: boolean;
}

export enum ApplyInstructionsType {
  Url = 'Url',
  Text = 'Text',
}

export interface IOppApplyExternalReponse {
  id: string;
  applyInstructions?: string;
  type: ApplyInstructionsType;
}

export enum OpportunityStatus {
  Approved = 'Approved',
  UnderReview = 'UnderReview',
  Rejected = 'Rejected',
}

export interface IAdminOppFilters extends IPagingFilter {
  status?: OpportunityStatus;
  type?: OpportunityType | 'All';
  isAssisted: boolean;
}

export interface IApplyOpportunity {
  message: string;
  subject: string;
  files: IFileMetadata[];
  isUploading: boolean;
  shareProfile: boolean;
}

export enum ApplicationStatusType {
  All = 'All',
  NotTrackable = 'Not Trackable',
  Delivered = 'Delvered',
  ViewedOnly = 'Viewed Only',
  Clicked = 'Clicked',
}

export enum RemoteType {
  Worldwide = 'Worldwide',
  SelectedCountries = 'SelectedCountries',
}

export interface OpportunityLocation {
  locationType: OpportunityLocationType | undefined;
  remoteType: RemoteType | undefined;
  selectedCountries: string[] | undefined;
  city: string | undefined;
  location: string | undefined; //deprecated
  additionalLocationInfo: string | undefined;
}

export interface ISearchOpp {
  categories: string[] | null;
  search: string | null;
  countries: string[] | null;
  roleTypes: string[];
  free: boolean;
}
