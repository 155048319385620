import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CountrySelect, {
  countries,
  CountrySelectMultiple,
  ICountryAutoComplete,
} from 'components/common/Select/CountrySelect';
import { FormikProps } from 'formik';
import { INewOpportunityForm, OpportunityLocationType, RemoteType } from 'global/interfaces/opportunity';
import { SyntheticEvent, useEffect, useState } from 'react';
import { getLeadLocationText } from 'services/locationService';
import { nameof } from 'ts-simple-nameof';
import { showError } from 'utils/errorHandler';
import { debounce } from 'lodash';

interface ILocationSectionProps {
  form: FormikProps<INewOpportunityForm>;
}

export default function LocationSection({ form }: ILocationSectionProps) {
  const [locationText, setLocationText] = useState('');

  const handleMultiCountryChange = (event: SyntheticEvent<Element, Event>, value: ICountryAutoComplete[]): void => {
    const countryCodes: string[] = value.map(c => c.code);
    form.setFieldValue(
      nameof<INewOpportunityForm>(x => x.selectedCountries),
      countryCodes,
      true,
    );
  };

  const handleSingleCountryChange = (_: React.SyntheticEvent, value: ICountryAutoComplete | null): void => {
    const countryCode: string = value?.code ?? '';
    form.setFieldValue(
      nameof<INewOpportunityForm>(x => x.selectedCountries),
      value ? [countryCode] : [],
      true,
    );
  };

  const rerenderFields = [
    form.values.city,
    form.values.selectedCountries,
    form.values.locationType,
    form.values.remoteType,
    form.values.location,
    form.values.additionalLocationInfo,
  ];

  useEffect(() => {
    const loadLocationText = () => {
      getLeadLocationText({
        city: form.values.city,
        location: form.values.location,
        locationType: form.values.locationType ?? undefined,
        remoteType: form.values.remoteType ?? undefined,
        selectedCountries: form.values.selectedCountries,
        additionalLocationInfo: form.values.additionalLocationInfo,
      })
        .then(res => setLocationText(res))
        .catch(showError);
    };

    const debouncedLoadLocation = debounce(loadLocationText, 500);

    debouncedLoadLocation();

    return () => {
      debouncedLoadLocation.cancel();
    };
  }, rerenderFields);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'h6'} mt={2}>
          Remote?
        </Typography>
        <Typography variant="body2" mt="4px">
          Is this opportunity fully Remote, Hybrid or On-site?
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <RadioGroup
          name={nameof<INewOpportunityForm>(x => x.locationType)}
          value={form.values.locationType}
          onChange={form.handleChange}
          row
        >
          <FormControlLabel value={OpportunityLocationType.Remote} control={<Radio />} label="Remote" />
          <FormControlLabel value={OpportunityLocationType.Hybrid} control={<Radio />} label="Hybrid" />
          <FormControlLabel value={OpportunityLocationType.Local} control={<Radio />} label="On-site" />
        </RadioGroup>

        {form.touched.locationType && Boolean(form.errors.locationType) && (
          <FormHelperText error>{form.errors.locationType}</FormHelperText>
        )}
      </Grid>

      {form.values.locationType === OpportunityLocationType.Remote && (
        <>
          <Grid item xs={12}>
            <Typography variant={'h6'} mt={2}>
              Worldwide?
            </Typography>
            <Typography variant="body2" mt="4px">
              Is this opportunity available worldwide or in select countries?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              name={nameof<INewOpportunityForm>(x => x.remoteType)}
              value={form.values.remoteType}
              onChange={form.handleChange}
              row
            >
              <FormControlLabel value={RemoteType.Worldwide} control={<Radio />} label="Worldwide" />
              <FormControlLabel value={RemoteType.SelectedCountries} control={<Radio />} label="Select Countries" />
            </RadioGroup>
            <FormHelperText error>
              {form.touched.remoteType && Boolean(form.errors.remoteType) ? form.errors.remoteType : ''}
            </FormHelperText>
          </Grid>
        </>
      )}
      {form.values.remoteType === RemoteType.SelectedCountries &&
        form.values.locationType === OpportunityLocationType.Remote && (
          <>
            <Grid item xs={12}>
              <Typography variant={'h6'} mt={2}>
                Countries
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CountrySelectMultiple
                label="Select Countries *"
                size="medium"
                values={
                  form.values.selectedCountries?.map(c => ({
                    code: c,
                    label: countries.find(x => x.code === c)?.label ?? 'Europe',
                  })) ?? []
                }
                onChange={handleMultiCountryChange}
                error={form.touched.selectedCountries !== undefined && Boolean(form.errors.selectedCountries)}
                helperText={
                  form.touched.selectedCountries !== undefined && Boolean(form.errors.selectedCountries)
                    ? form.errors.selectedCountries
                    : ''
                }
              />
            </Grid>
          </>
        )}
      {(form.values.locationType === OpportunityLocationType.Local ||
        form.values.locationType === OpportunityLocationType.Hybrid) && (
        <>
          <Grid item xs={12}>
            <Typography variant={'h6'} mt={2}>
              Country
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CountrySelect
              label="Select a Country *"
              size="medium"
              value={
                form.values.selectedCountries && form.values.selectedCountries[0]
                  ? form.values.selectedCountries[0]
                  : undefined
              }
              onChange={handleSingleCountryChange}
              error={form.touched.selectedCountries !== undefined && Boolean(form.errors.selectedCountries)}
            />
            <FormHelperText error>
              {form.touched.selectedCountries && Boolean(form.errors.selectedCountries)
                ? form.errors.selectedCountries
                : ''}
            </FormHelperText>
          </Grid>
        </>
      )}
      {(form.values.locationType == OpportunityLocationType.Local ||
        form.values.locationType === OpportunityLocationType.Hybrid) && (
        <>
          <Grid item xs={12}>
            <Typography variant={'h6'} mt={2}>
              City
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl>
                <TextField
                  name={nameof<INewOpportunityForm>(x => x.city)}
                  label="City *"
                  variant="outlined"
                  fullWidth
                  value={form.values.city}
                  onChange={form.handleChange}
                  error={form.touched.city !== undefined && Boolean(form.errors.city)}
                  helperText={form.touched.city !== undefined ? form.errors.city : ''}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography variant={'h6'} mt={2}>
          Additional Location Details
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormGroup>
          <FormControl>
            <TextField
              name={nameof<INewOpportunityForm>(x => x.additionalLocationInfo)}
              label="Additional Info"
              variant="outlined"
              placeholder="PST time zone preferred"
              fullWidth
              value={form.values.additionalLocationInfo}
              onChange={form.handleChange}
              error={form.touched.additionalLocationInfo !== undefined && Boolean(form.errors.additionalLocationInfo)}
              helperText={form.touched.additionalLocationInfo !== undefined ? form.errors.additionalLocationInfo : ''}
            />
          </FormControl>
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={'h6'} mt={2}>
          Location Preview
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormGroup>
          <FormControl>
            <Typography variant="subtitle2">{locationText}</Typography>
          </FormControl>
        </FormGroup>
      </Grid>
    </>
  );
}
