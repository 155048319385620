import { Close } from '@mui/icons-material';
import { Dialog, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StyledCTAButtton } from 'components/common/Button/CTAs';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { AuthContext } from 'contexts/AuthContext';
import { Dispatch, useContext, useState } from 'react';
import { pauseSubscription } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';

export interface IPauseProps {
  loading: boolean;
  setLoading: Dispatch<boolean>;
}

export function PauseSubscription(props: IPauseProps) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <StyledCTAButtton variant="contained" fullWidth onClick={() => setShowDialog(true)} loading={props.loading}>
        Pause
      </StyledCTAButtton>
      <PauseDialog {...props} open={showDialog} onClose={() => setShowDialog(false)} />
    </>
  );
}

export interface PauseDialogProps extends IPauseProps {
  open: boolean;
  onClose: () => void;
}

function PauseDialog(props: PauseDialogProps) {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handlePause = () => {
    props.setLoading(true);
    pauseSubscription()
      .then(() => authContext.refresh())
      .catch(showError)
      .finally(() => props.setLoading(false));
  };

  return (
    <Dialog
      open={props.open}
      onClose={!props.loading ? props.onClose : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
    >
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          Pause subscription
        </Typography>
        <IconButton onClick={() => (!props.loading ? props.onClose() : undefined)}>
          <Close />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        If you’re not quite ready to leave but need a break, you can pause your plan at 50% discount for as long as
        needed. Your profile will remain boosted on our talent network but you will not have access to Premium gigs.
      </StyledDialogContent>
      <StyledDialogActions>
        <Grid container justifyContent={{ xs: 'center', sm: 'right' }} gap={1}>
          <Grid item xs={12} sm={4}>
            <RoundButton variant="outlined" fullWidth onClick={props.onClose} disabled={props.loading}>
              Cancel
            </RoundButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <RoundButton variant="contained" fullWidth type="submit" loading={props.loading} onClick={handlePause}>
              Pause Now
            </RoundButton>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </Dialog>
  );
}
