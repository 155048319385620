import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FaqOption } from 'global/interfaces/faq';
import { useState } from 'react';
import { Container, useMediaQuery } from '@mui/material';

interface FAQProps {
  description: string;
  options: FaqOption[];
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: 'none',
    borderBottom: `1px solid ${theme.palette.common.black}`,
    backgroundColor: 'transparent',

    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: '0px',
  [theme.breakpoints.up('lg')]: {
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    height: '30px',
    width: '30px',
    marginLeft: '8px',

    '&>svg': {
      height: 'inherit',
      width: 'inherit',
    },
  },

  [theme.breakpoints.down('md')]: {
    paddingBlock: '17px',

    '& .MuiAccordionSummary-expandIconWrapper': {
      height: '24px',
      width: '24px',
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  paddingBottom: '20px',
}));

const AccordionSummaryText = styled(Typography)(({ theme }) => ({
  paddingRight: '40px',
  paddingTop: '5px',

  [theme.breakpoints.down('sm')]: {
    paddingRight: '26px',
    paddingTop: '5px',
  },
}));

export default function NewFAQ({ options }: FAQProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Box pb={isMobile ? 6 : 12} pt={isMobile ? 6 : 10} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          padding={{ xs: '20px', sm: '40px' }}
          borderRadius={'24px'}
          sx={{
            background: '#FFFFFF',
            border: '1px solid #D9D8D6',
          }}
        >
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize={{ xs: '32px', md: '36px', lg: '60px' }}
            textAlign={isMobile ? 'left' : 'center'}
            paddingLeft={'6px'}
          >
            FAQs
          </Typography>

          <Box pt={{ xs: '10px', md: '24px' }}>
            {options.map((question, optionIndex) => (
              <Accordion
                key={question.id}
                expanded={`panel${optionIndex}` === expanded}
                onChange={handleChange(`panel${optionIndex}`)}
                sx={{ borderColor: '#D0D5DD' }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  sx={{ paddingLeft: '8px', paddingRight: '8px', paddingTop: '12px', paddingBottom: '12px' }}
                >
                  <Typography variant="subtitle1" color="#101828" fontSize={{ xs: '16px' }}>
                    {question.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AccordionSummaryText
                    variant="body1"
                    whiteSpace="break-spaces"
                    color="#344054"
                    fontSize={{ xs: '16px', paddingLeft: '8px', paddingRight: '8px' }}
                  >
                    {question.answer}
                  </AccordionSummaryText>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
