import React from 'react';

export function strictNumericCheck(e: React.KeyboardEvent): boolean {
  const key: string = e.key.toLocaleLowerCase();
  const isInvalidChar: boolean = key === 'e' || key === '+' || key === '-';
  if (isInvalidChar) {
    e.preventDefault();
    return false;
  }
  return true;
}

export const handleNumberChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  next: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  defaultNumber?: number | string,
) => {
  const defaultToUse = defaultNumber !== undefined ? defaultNumber : 0;
  const addDecimal =
    event.target.value && event.target.value.endsWith('.') && event.target.value.split('.').length <= 2;
  const parsedFloat = parseFloat(event.target.value);
  const alwaysInt = isNaN(parsedFloat) ? defaultToUse : parsedFloat;
  event.target.value = addDecimal ? `${alwaysInt}.` : `${alwaysInt}`;
  if (event.target.value.split('.').length == 2) {
    const parts = event.target.value.split('.');
    const part2 = parts[1];
    if (part2.length > 2) event.target.value = `${parts[0]}.${part2.substring(0, 2)}`;
  }

  next(event);
};
