import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import ManageNewsLetterForm from './ManageNewsLetterForm';

export default function ManageNewsletterComponent() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [updated, setUpdated] = useState(false);

  return (
    <Box mt={isMobileScreen ? '32px' : '72px'}>
      <Container maxWidth="md">
        <Stack gap={1}>
          <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Newsletter Preferences</Typography>
          {!updated ? (
            <ManageNewsLetterForm setUpdated={setUpdated} />
          ) : (
            <>
              <Box marginTop={1}>
                <Typography variant="body1" component="span">
                  Your newsletter preferences have been updated!
                </Typography>
                <CheckCircleIcon color="success" sx={{ ml: 1, mb: -0.5 }} />
              </Box>
              <Link to="/gigs" style={{ textDecoration: 'none' }}>
                <Typography variant="body1" color="primary">
                  View All Gigs
                </Typography>
              </Link>
            </>
          )}
        </Stack>
      </Container>
    </Box>
  );
}
