import Grid from '@mui/material/Grid';
import './MultiFileUpload.sass';
import NoFilesIcon from 'components/common/StyledIcons/NoFilesIcon';
import { FormHelperText, ImageList, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IUploadQueue } from 'global/interfaces/user';
import React, { useEffect, useState } from 'react';
import PortfolioCarouselDialog from './PortfolioCarouselDialog';
import { getPreUploadFormFile } from 'utils/file';
import PortfolioTiles from './PortfolioTiles';
import UploadProgress from './UploadProgress';
import { IFileMetadata } from 'global/interfaces/file';
import FileUploadButton from '../Button/FileUploadButton';
import { StyledImageListItem } from './PortfolioFilesStyles';
import { supportedPortfolioFileTypes } from 'global/Constants/SupportedFileTypes';
import CategoryLookups from 'json/CategoryLookups.json';
import { useSearchParams } from 'react-router-dom';
import { adminParam } from 'global/constants';

interface IMultiFileUploadProps {
  onAddFile: (file: IFileMetadata) => void;
  onDelete: (path: string) => void;
  onMoveLeft: (path: string) => void;
  onMoveRight: (path: string) => void;
  onNameChange: (path: string, fileName: string) => void;
  onUploadStatusChange: (status: boolean) => void;
  files: IFileMetadata[];
  error?: string | string[];
  isSellingServices: boolean;
  mainCategory?: string | null;
  needsVettingValidation?: boolean;
  uploadFunc: (file: File, controller: AbortController) => Promise<IFileMetadata>;
}

const defaultMaxFileSize = 100 * 1024 * 1024;

export default function MultiFileUpload(props: IMultiFileUploadProps): JSX.Element {
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);
  const [viewerActiveIndex, setViewerActiveIndex] = useState<number>(0);
  const [uploadQueue, setUploadQueue] = useState<IUploadQueue[]>([]);
  const [disAllowedFileTypes, setDisAllowedFileTypes] = useState<Array<File>>([]);
  const [invalidSizedFiles, setInvalidSizedFiles] = useState<Array<File>>([]);
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const handleViewerClose: React.MouseEventHandler = () => {
    setIsViewerOpen(false);
    setViewerActiveIndex(0);
  };
  const isSellerSignup: boolean = props.isSellingServices;
  const category = CategoryLookups.find(f => f.id === props.mainCategory)!;

  const onClickFile = (e: React.MouseEvent, index: number): void => {
    setIsViewerOpen(true);
    setViewerActiveIndex(index);
  };

  useEffect(() => {
    props.onUploadStatusChange(uploadQueue.length > 0);
  }, [uploadQueue.length]);

  const onNewFilesSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setDisAllowedFileTypes([]);
    setInvalidSizedFiles([]);
    const newFiles: FileList | null = e.target.files;

    if (newFiles === null || newFiles.length === 0) {
      return;
    }

    const supportedFileMimes = Object.keys(supportedPortfolioFileTypes).map(
      k => supportedPortfolioFileTypes[k as keyof typeof supportedPortfolioFileTypes],
    );

    let allowedFileTypes: File[] = Array.from(newFiles);

    allowedFileTypes = allowedFileTypes.filter(f => supportedFileMimes.some(m => m.includes(f.type)));

    const disallowedFiles = Array.from(newFiles).filter(f => !allowedFileTypes.some(a => a === f));
    setDisAllowedFileTypes(disallowedFiles);

    if (allowedFileTypes.length === 0) {
      return;
    }

    const invalidFiles = validateFileSizes(allowedFileTypes);
    setInvalidSizedFiles(invalidFiles);

    const validFiles = allowedFileTypes.filter(a => !invalidFiles.includes(a));
    if (validFiles.length > 0) {
      const newFilesQueue = validFiles.map(x => getPreUploadFormFile(x));
      setUploadQueue(currentQueue => [...currentQueue, ...newFilesQueue]);
    }
  };

  const onUploadComplete = (tempId: string, response: IFileMetadata): void => {
    const isNotCancelled = uploadQueue.some(x => x.tempId == tempId);
    if (isNotCancelled) {
      setUploadQueue(currentQueue => currentQueue.filter(x => x.tempId !== tempId));
      props.onAddFile(response);
    }
  };

  const onUploadCancel = (tempId: string): void => {
    setUploadQueue(currentQueue => currentQueue.filter(x => x.tempId !== tempId));
  };

  return (
    <React.Fragment>
      <Grid>
        <Typography variant="body2">
          {!isSellerSignup ? (
            'Recommended: Save time by adding images or documents which showcase your brand, business or style (please ensure you have ownership or usage rights)'
          ) : (
            <>
              {props.needsVettingValidation ? 'Required: ' : 'Recommended: '}
              {category?.helperText ?? ''}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} mt={3}>
        <FileUploadButton
          multiple
          accept={Object.keys(supportedPortfolioFileTypes).join(', ')}
          disabled={searchParams.has(adminParam)}
          description={
            <Typography variant="caption" color="grey.600" textAlign="center">
              Images, Videos, Audio Files, PDF Docs{' '}
              <Tooltip
                title="Image (PNG, JPG, JPEG, WEBP, GIF, TIFF, BMP), Video (MP4, MOV, WMV, AVI, MKV, MPEG), Audio (AAC, MP3, WAV) or PDF"
                enterTouchDelay={0}
                leaveTouchDelay={10000}
                describeChild
                arrow
                placement="bottom-start"
                onClick={e => e.stopPropagation()}
              >
                <Typography variant="caption" color="grey.600">
                  <a href="#" onClick={e => e.preventDefault()}>
                    Learn more
                  </a>
                </Typography>
              </Tooltip>
            </Typography>
          }
          onImageChange={onNewFilesSelect}
        />
        {disAllowedFileTypes.length > 0 && (
          <FormHelperText error>
            {disAllowedFileTypes.map(
              (f: File, i: number) => `${f.name}${i !== disAllowedFileTypes.length - 1 ? ', ' : ''}`,
            )}
            &nbsp;contain file types that are not supported!
          </FormHelperText>
        )}
        {invalidSizedFiles.length > 0 && (
          <FormHelperText error>
            {invalidSizedFiles.map(
              (f: File, i: number) =>
                `${f.name} ${i !== invalidSizedFiles.length - 1 ? ', ' : ''} ${f.length > 1 ? 'are' : 'is'} more than ${
                  defaultMaxFileSize / 1024 / 1024
                }MB!`,
            )}
          </FormHelperText>
        )}
      </Grid>

      {props.error && typeof props.error === 'string' && (
        <Grid sx={{ p: 1, paddingLeft: 0 }} item xs={12}>
          <FormHelperText error>{props.error}</FormHelperText>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12}>
        <ImageList cols={isMobile ? 2 : 3} gap={24} sx={{ mt: 3, mb: 0, overflow: 'visible' }}>
          <PortfolioTiles
            files={props.files}
            onClickFile={onClickFile}
            onDelete={props.onDelete}
            onMoveLeft={props.onMoveLeft}
            onMoveRight={props.onMoveRight}
            onNameChange={props.onNameChange}
            errors={props.error && Array.isArray(props.error) ? props.error : undefined}
          />
          {uploadQueue.map((item: IUploadQueue, i: number) => (
            <StyledImageListItem key={i}>
              <UploadProgress
                onUploadComplete={onUploadComplete}
                onUploadCancel={onUploadCancel}
                upload={item}
                uploadFunc={props.uploadFunc}
              />
            </StyledImageListItem>
          ))}
        </ImageList>

        {props.files?.length === 0 && uploadQueue.length === 0 && <NoFilesIcon isSellingServices={true} />}
        <PortfolioCarouselDialog
          initialIndex={viewerActiveIndex}
          files={props.files}
          isOpen={isViewerOpen}
          onClose={handleViewerClose}
        />
      </Grid>
    </React.Fragment>
  );
}

function validateFileSizes(files: File[]): Array<File> {
  return files.filter(f => f.size > defaultMaxFileSize);
}
