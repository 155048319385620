import { IconButton, Menu, MenuItem, styled } from '@mui/material';
import DeleteProfileDialog from 'components/common/DeleteProfileDialog/DeleteProfile';
import { getShowPaymentsByCountryCode } from 'components/common/Select/CountrySelect';
import { UserAvatar } from 'components/common/UserAvatar';
import { AuthContext } from 'contexts/AuthContext';
import { UserRole } from 'global/enums/userRole';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'services/authService';

const StyledAvatar = styled(UserAvatar)(({ theme }) => ({
  height: 40,
  width: 40,

  [theme.breakpoints.down('sm')]: {
    height: '30px',
    width: '30px',
    fontSize: '14px',
  },
}));

export function UserAppDrawer() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showPaymentsItems = authContext?.user ? getShowPaymentsByCountryCode(authContext.user.country) : false;

  const [isDeleteProfileVisible, setIsDeleteProfileVisible] = useState<boolean>(false);

  const hasAdminRole = authContext.user && authContext.user.roles.indexOf(UserRole.Administrator) > -1;

  const handleMenu = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleNavigationClick =
    (route: string) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      e.stopPropagation();
      navigate(route);
      setAnchorEl(null);
    };

  const handleShowDeleteProfile = (): void => {
    setAnchorEl(null);
    setIsDeleteProfileVisible(true);
  };

  const handleLogoutClick = (): void => {
    logout();
    authContext.refresh().finally(() => {
      navigate('/');
      setAnchorEl(null);
    });
  };

  const handleCloseDeleteProfile = (): void => {
    setIsDeleteProfileVisible(false);
  };

  return (
    <>
      {authContext.user && (
        <>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            sx={{ padding: 0 }}
            size="small"
          >
            <StyledAvatar
              userId={authContext.user?.id}
              displayName={`${authContext.user?.firstName} ${authContext.user?.lastName}`}
              profileImageId={authContext.user?.profileImageId}
              noLink
            />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleNavigationClick(`/users/${authContext.user?.id}`)}>My Profile</MenuItem>
            {showPaymentsItems && <MenuItem onClick={handleNavigationClick(`/contracts`)}>Contracts</MenuItem>}
            {showPaymentsItems && (
              <MenuItem onClick={handleNavigationClick('/network/connections')}>Connections</MenuItem>
            )}
            <MenuItem onClick={handleNavigationClick('/subscriptions')}>My Subscriptions</MenuItem>
            <MenuItem onClick={handleNavigationClick('/gigs/my-proposals')}>My Proposals</MenuItem>
            {showPaymentsItems && (
              <MenuItem onClick={handleNavigationClick('/payout')}>Payout Details / Branding</MenuItem>
            )}
            <MenuItem onClick={handleNavigationClick('/newsletter')}>Newsletter Management</MenuItem>
            <MenuItem onClick={handleNavigationClick('/password/change')}>Change Password</MenuItem>
            <MenuItem color="error" onClick={handleShowDeleteProfile}>
              Delete Profile
            </MenuItem>
            {hasAdminRole && <MenuItem onClick={handleNavigationClick('/admin/users')}>Vetting</MenuItem>}
            {hasAdminRole && (
              <MenuItem onClick={handleNavigationClick('/admin/newexternaljob')}>Post External Gig</MenuItem>
            )}
            {hasAdminRole && <MenuItem onClick={handleNavigationClick('/admin/internal-jobs')}>All Gigs</MenuItem>}
            {hasAdminRole && (
              <MenuItem onClick={handleNavigationClick('/admin/connectionattempts')}>Connection Attempts</MenuItem>
            )}
            {hasAdminRole && <MenuItem onClick={handleNavigationClick('/admin/skills')}>Skills</MenuItem>}
            {hasAdminRole && <MenuItem onClick={handleNavigationClick('/disputes')}>Disputes</MenuItem>}
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </>
      )}
      <DeleteProfileDialog
        open={isDeleteProfileVisible}
        onClose={handleCloseDeleteProfile}
        onLogout={handleLogoutClick}
      />
    </>
  );
}
