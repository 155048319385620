import Grid from '@mui/material/Grid';
import NoUsersIcon from 'components/common/StyledIcons/NoUsersIcon';
import UserSection from 'components/findUser/UserSection';
import { UserOrderingGroup } from 'global/enums/userOrderingGroup';
import { IFrontUserBase } from 'global/interfaces/user';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getFrontUsers, updateUserViews } from 'services/userService';
import { showError } from 'utils/errorHandler';
import UserCategories from 'components/findUser/UserCategories';
import { useTitle } from 'utils/router';
import { VerticalCardSkeleton } from 'components/common/Skeleton/VerticalCardSkeleton';
import { Box, Container, debounce } from '@mui/material';
import CategoryLookups from 'json/CategoryLookups.json';

const pageSize = 32;

interface IUserCategory {
  users: IFrontUserBase[];
  loading: boolean;
  title: string;
}

const Home = () => {
  useTitle('Shoutt | Explore our talent');

  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  let categories = CategoryLookups.filter(c => !!c.home);

  if (category != null) {
    categories = categories.sort((a, _) => (a.label == category ? -1 : 1));
  }

  const defaultUserCategories: { [key: string]: IUserCategory } = categories.reduce((dict, cat) => {
    dict[cat.id] = {
      title: cat.label,
      loading: true,
      users: [],
    };
    return dict;
  }, {} as { [key: string]: IUserCategory });

  const [userCategories, setUserCategories] = useState<{ [key: string]: IUserCategory }>(defaultUserCategories);

  const viewedUsers = useRef<string[]>([]);
  const viewedUsersQueue = useRef<string[]>([]);

  useEffect(() => {
    categories.forEach(p => fetchUsers(p.id));
  }, []);

  const fetchUsers = (category: string) => {
    getFrontUsers({
      orderingGroup: UserOrderingGroup.Featured,
      category,
      pageSize,
    })
      .then((responseUsers: IFrontUserBase[]) => {
        setUserCategories(prev => ({
          ...prev,
          [category]: { ...prev[category], users: responseUsers },
        }));
      })
      .catch(showError)
      .finally(() => {
        setUserCategories(prev => ({
          ...prev,
          [category]: { ...prev[category], loading: false },
        }));
      });
  };

  const enqueueUserViewUpdate = debounce(() => {
    if (viewedUsersQueue.current.length > 0) {
      updateUserViews(viewedUsersQueue.current);
      viewedUsersQueue.current = [];
    }
  }, 200);

  const handleOnInViewUserCard = (userId: string): void => {
    const currentViewedUsers = viewedUsers.current;
    const isViewed = currentViewedUsers.includes(userId);

    if (!isViewed) {
      viewedUsers.current = [...new Set([...currentViewedUsers, userId])];
      viewedUsersQueue.current = [...new Set([...viewedUsersQueue.current, userId])];
      enqueueUserViewUpdate();
    }
  };
  const [muted, setMuted] = useState(true);
  const [activeUserId, setActiveUserId] = useState('');

  const setActiveUser = (userId: string, isActive: boolean) => {
    setActiveUserId(isActive ? userId : '');
  };

  const renderCategory = (index: number, userCategoriesArray: [string, IUserCategory][], activeUserId: string) => {
    const [, userCategory] = userCategoriesArray[index];

    return (
      <Grid item xs={12} key={`usercategory-${index}`}>
        {userCategory && userCategory.loading && <VerticalCardSkeleton />}
        {userCategory && userCategory.users.length > 0 && (
          <UserSection
            title={userCategory.title}
            onInViewUserCard={handleOnInViewUserCard}
            users={userCategory.users}
            muted={muted}
            setMuted={setMuted}
            activeUserId={activeUserId}
            setActiveUser={setActiveUser}
          />
        )}
      </Grid>
    );
  };

  return (
    <Box bgcolor="#F8F7F4">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <UserCategories />
          </Grid>

          {renderCategory(0, Object.entries(userCategories), activeUserId)}

          {Array.from({ length: Object.entries(userCategories).length - 1 }, (_, i) => i + 1).map(key =>
            renderCategory(key, Object.entries(userCategories), activeUserId),
          )}

          {Object.entries(userCategories).every(
            ([, userCategory]) => userCategory.users.length === 0 && !userCategory.loading,
          ) && <NoUsersIcon />}
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
