import { IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useState } from 'react';
import { unpauseSubscription } from 'services/subscriptionService';
import { AuthContext } from 'contexts/AuthContext';
import { showError } from 'utils/errorHandler';

interface UnPauseDetails {
  onClose: () => void;
}

export function UnpauseDetails({ onClose }: UnPauseDetails) {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);

  const handleUnpause = () => {
    setLoading(true);
    unpauseSubscription()
      .then(() => authContext.refresh())
      .catch(showError)
      .finally(() => setLoading(false));
  };

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h5" color={theme.palette.grey[900]}>
          Your Premium Plan is Paused
        </Typography>
        <IconButton onClick={!loading ? onClose : undefined} sx={{ marginRight: '-12px' }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        You can’t currently access premium features as your premium plan is paused. You can unpause now and your access
        to premium features will be restored immediately. You will be charged full price at your next billing cycle.
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={onClose} disabled={loading}>
          Cancel
        </RoundButton>
        <RoundButton fullWidth={isMobileScreen} variant="contained" onClick={handleUnpause} loading={loading}>
          Unpause Now
        </RoundButton>
      </StyledDialogActions>
    </>
  );
}
