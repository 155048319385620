import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FormSelect from 'components/common/Select/FormSelect';
import { useFormik } from 'formik';
import { Dispatch, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RoundButton } from 'components/common/Button/RoundButton';
import { AuthContext } from 'contexts/AuthContext';
import CountrySelect, { ICountryAutoComplete } from 'components/common/Select/CountrySelect';
import { nameof } from 'ts-simple-nameof';
import CategoryMultiSelect, { MenuProps } from 'components/common/Select/CategoryMultiSelect';
import { IAdminOppFilters, IOpportunity, ISearchOpp, OpportunityLocationType } from 'global/interfaces/opportunity';
import { mapToSearchParams } from 'utils/search';
import { getAdminOpportunities, getOpportunities } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';

interface IOppFilterProps {
  children?: React.ReactNode;
  setLoading: Dispatch<boolean>;
  setOpportunities: Dispatch<IOpportunity[]>;
  refresh?: boolean;
  isAdminComponent?: boolean;
  adminOppFilters?: IAdminOppFilters;
}

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: 0,
  },
  fieldset: {
    borderRadius: '12px',
  },
}));

export const StyledSelectField = styled(FormSelect)(({ theme }) => ({
  borderRadius: '12px',
  fieldset: {
    borderColor: theme.palette.grey[200],
    borderRadius: '12px',
  },
}));

export default function OppFilters({
  children,
  setLoading,
  setOpportunities,
  refresh,
  isAdminComponent,
  adminOppFilters,
}: IOppFilterProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.user && authContext.user.filterQuery && !isAdminComponent) {
      const params = new URLSearchParams(authContext.user.filterQuery);
      setSearchParams(params);
    }
  }, [authContext.user?.filterQuery]);

  const form = useFormik<ISearchOpp>({
    initialValues: {
      categories: searchParams.getAll('categories'),
      search: searchParams.get('search') ?? null,
      countries:
        searchParams.has('countries') && !isAdminComponent
          ? searchParams.getAll('countries')
          : authContext.user?.country && !isAdminComponent
          ? [authContext.user?.country ?? '']
          : [],
      roleTypes:
        searchParams.getAll('roleTypes').length > 0
          ? searchParams.getAll('roleTypes')
          : [OpportunityLocationType.Remote, OpportunityLocationType.Hybrid, OpportunityLocationType.Local],
      free: searchParams.get('free') === 'true',
    },
    enableReinitialize: true,
    onSubmit: values => {
      setLoading(true);
      const params = mapToSearchParams(values);
      if (!isAdminComponent) {
        fetchUserOpps(params);
        return;
      }

      fetchAdminOpps(values);
    },
  });

  const handleCountryChange = (_: React.SyntheticEvent, value: ICountryAutoComplete | null): void => {
    const countryCode: string = value?.code ?? '';
    form.setFieldValue(
      nameof<ISearchOpp>(x => x.countries),
      value ? [countryCode] : [],
      true,
    );
  };

  useEffect(() => {
    if (!authContext.loading) {
      form.submitForm();
    }
  }, [refresh, authContext.loading, adminOppFilters]);

  const fetchUserOpps = (params: URLSearchParams) => {
    getOpportunities(params)
      .then(opps => {
        setOpportunities(opps);
        if (authContext.user && !isAdminComponent) {
          authContext.setFilterQuery(params);
        } else {
          setSearchParams(params);
        }
      })
      .catch(showError)
      .finally(() => setLoading(false));
  };

  const fetchAdminOpps = (values: ISearchOpp) => {
    if (!isAdminComponent || !adminOppFilters) return;
    const params: URLSearchParams = mapToSearchParams({ ...adminOppFilters, ...values });

    getAdminOpportunities(params)
      .then((retrievedOpps: IOpportunity[]) => {
        setOpportunities(retrievedOpps);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
        setSearchParams(params);
      });
  };

  return (
    <Stack
      sx={{ mb: !isMobile ? 5 : 0, mt: !isMobile ? 5 : 4 }}
      spacing={2}
      direction={'column'}
      component="form"
      onSubmit={form.handleSubmit}
    >
      <Typography variant="subtitle1" mb={0.25}>
        Filter gigs
      </Typography>
      <CountrySelect
        value={form.values.countries && form.values.countries[0] ? form.values.countries[0] : undefined}
        label="Countries"
        size={'small'}
        onChange={handleCountryChange}
      />
      <FormControl fullWidth>
        <InputLabel id="multiple-category-label" size="small">
          Role Types
        </InputLabel>
        <Select
          labelId="multiple-role-types-label"
          multiple
          name="roleTypes"
          value={form.values.roleTypes}
          size="small"
          displayEmpty
          onChange={form.handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Role Types" />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={value !== OpportunityLocationType.Local ? value : 'On-site'} size="small" />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem value={OpportunityLocationType.Remote}>
            <Checkbox
              checked={form.values.roleTypes ? form.values.roleTypes?.includes(OpportunityLocationType.Remote) : false}
            />
            <ListItemText primary="Remote" />
          </MenuItem>
          <MenuItem value={OpportunityLocationType.Hybrid}>
            <Checkbox
              checked={form.values.roleTypes ? form.values.roleTypes?.includes(OpportunityLocationType.Hybrid) : false}
            />
            <ListItemText primary="Hybrid" />
          </MenuItem>
          <MenuItem value={OpportunityLocationType.Local}>
            <Checkbox
              checked={form.values.roleTypes ? form.values.roleTypes?.includes(OpportunityLocationType.Local) : false}
            />
            <ListItemText primary="On-site" />
          </MenuItem>
        </Select>
      </FormControl>
      <CategoryMultiSelect
        value={form.values.categories ? [...form.values.categories] : []}
        homeCategoriesOnly={false}
        label="Categories"
        onChange={changedCategories =>
          form.setFieldValue(
            nameof<ISearchOpp>(x => x.categories),
            changedCategories.target.value,
          )
        }
        input={<OutlinedInput id="select-multiple-chip" label="Categories" />}
        error={form.touched.categories !== undefined && Boolean(form.errors.categories)}
        helpertext={form.errors.categories ?? ''}
        size="small"
      />
      <StyledTextField
        name="search"
        label="Keywords"
        value={form.values.search}
        onChange={form.handleChange}
        autoComplete="off"
        placeholder="PPC, Paid Media, Google Ads"
        size={'small'}
        fullWidth
        InputProps={{
          autoComplete: 'off',
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography alignItems={'center'} display={'flex'} ml={-1.5} mt={-1}>
            <Checkbox
              onChange={form.handleChange}
              name={nameof<ISearchOpp>(x => x.free)}
              checked={form.values.free}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
            />
            Free to apply only
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <RoundButton variant="contained" type="submit" sx={{ marginTop: '-4px', width: '100%' }}>
            Update Results
          </RoundButton>
        </Grid>
      </Grid>
      {children}
    </Stack>
  );
}
