import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { ActiveDiscountDetails } from './activeDiscountDetails';
import { UserSubscription } from 'global/interfaces/userSubscription';

export default function CancelSubscriptionDialog({
  open,
  handleClose,
  loadSubscription,
  subscription,
}: {
  open: boolean;
  handleClose: () => void;
  loadSubscription: () => void;
  subscription: UserSubscription | undefined;
}) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={!loading ? handleClose : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
    >
      <ActiveDiscountDetails
        loading={loading}
        setLoading={setLoading}
        loadSubscription={loadSubscription}
        handleClose={handleClose}
        subscription={subscription}
      />
    </Dialog>
  );
}
