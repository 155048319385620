import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { AuthContext } from 'contexts/AuthContext';
import * as React from 'react';
import { login } from 'services/authService';
import { WelcomeGraphicContainer } from 'components/common/WelcomeGraphicContainer';
import { FormHelperText, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { getRedirectUrl, redirectToSignUpSeller, useTitle } from 'utils/router';
import { useContext, useState } from 'react';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import { AuthForm } from 'components/common/form/AuthForm';
import { LoadingButton } from '@mui/lab';
import { RoundButton } from 'components/common/Button/RoundButton';

const StyledForgotButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary[600],
  marginLeft: '-16px',
  marginTop: '16px',
}));

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: '18px 0',
  },
}));

function Login(): JSX.Element {
  useTitle('Login');
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isIncorrectLogin, setIsIncorrectLogin] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  const navigateBack = () => navigate(getRedirectUrl(location), { replace: true });

  React.useEffect(() => {
    if (authContext.user) {
      navigateBack();
    }
  }, [authContext]);

  const handleLoginFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    login(email, password)
      .then(() => {
        setIsIncorrectLogin(false);

        authContext.refresh().then(() => {
          setLoading(false);
          navigateBack();
        });
      })
      .catch((err: IApiError) => {
        setLoading(false);
        if (err.status == 401 || err.status == 403) {
          setIsIncorrectLogin(true);
        } else {
          showError(err);
        }
      });
  };

  return (
    <section>
      <WelcomeGraphicContainer>
        <AuthForm onSubmit={handleLoginFormSubmit} sx={{ maxWidth: '488px' }}>
          {/* JE: Dislike this maxWidth but only easy way to prevent the login screen growing on error without rebulding this page*/}
          <Typography textAlign="center" mt={3} variant={isMobile ? 'h5' : 'h4'} color={theme.palette.grey[900]}>
            Sign in to your account
          </Typography>
          <Grid container spacing={3} marginTop={{ xs: 2, sm: 3 }}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControl variant="standard">
                  <TextField
                    size={isMobile ? 'small' : 'medium'}
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    autoComplete="username"
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControl variant="standard">
                  <TextField
                    size={isMobile ? 'small' : 'medium'}
                    label="Password"
                    variant="outlined"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />
                </FormControl>
              </FormGroup>
              {isIncorrectLogin && (
                <FormHelperText error sx={{ marginTop: '24px' }}>
                  Email address or password is incorrect
                </FormHelperText>
              )}
              <Stack justifyContent="center">
                <StyledForgotButton size="medium" variant="text" onClick={() => navigate('/password/forgot')}>
                  Forgot your password?
                </StyledForgotButton>
              </Stack>
            </Grid>
          </Grid>
          <Grid container marginTop={4} justifyContent="center">
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                type="submit"
                size={isMobile ? 'small' : 'large'}
                fullWidth
                loading={loading}
              >
                Sign in
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container marginTop={2} justifyContent="center">
            <Grid item xs={12}>
              <RoundButton
                color="inherit"
                size={isMobile ? 'small' : 'large'}
                variant="text"
                fullWidth
                onClick={() => redirectToSignUpSeller(navigate, location)}
              >
                New customer? Sign up here
              </RoundButton>
            </Grid>
          </Grid>
        </AuthForm>
      </WelcomeGraphicContainer>
    </section>
  );
}

export default Login;
