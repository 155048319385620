import EastIcon from '@mui/icons-material/East';
import { Box, CircularProgress, Grid, styled, Typography, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { AuthContext } from 'contexts/AuthContext';
import { applyOpportunityLogin } from 'global/constants';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { IOpportunity } from 'global/interfaces/opportunity';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { useLocation, useNavigate } from 'react-router-dom';
import { redirectToSignUpSeller } from 'utils/router';
import { pushToDataLayer } from 'utils/tagHelper';
import ApplyDialog from './apply/ApplyDialog';
import OppFilters from './OppFilters';
import OpportunityAccordion from './OpportunityAccordion';
import { UserRole } from 'global/enums/userRole';

export const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));

export default function ViewOpportunitiesComponent({ setNumOpps }: { setNumOpps?: Dispatch<number> }) {
  const [selectedOpportunity, setSelectedOpportunity] = useState<IOpportunity | null>(null);
  const [applyDialogOpen, setApplyDialogOpen] = useState<boolean>(false);
  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const authContext = useContext(AuthContext);
  const loggedIn: boolean = authContext.user !== null;

  const navigate = useNavigate();
  const location = useLocation();

  const applyNow = (opportunity: IOpportunity): void => {
    setSelectedOpportunity(opportunity);
    if (!loggedIn) {
      pushToDataLayer(applyOpportunityLogin, {
        transaction_id: opportunity.id,
      });
      redirectToSignUpSeller(navigate, location);
    } else {
      setApplyDialogOpen(true);
    }
  };

  const onApplyClose = (): void => {
    setSelectedOpportunity(null);
    setApplyDialogOpen(false);
  };

  const updateOpportunity = (opportunity: IOpportunity): void => {
    setOpportunities(current => current.map(c => (c.id == opportunity.id ? opportunity : c)));
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewOpportunity],
    (res: IOpportunity) => {
      if (
        authContext.user?.subscriptionActive ||
        (authContext.user && authContext.user?.roles.indexOf(UserRole.Administrator) > -1)
      ) {
        setOpportunities(current => [res, ...current]);
      }
    },
    [],
  );

  useEffect(() => {
    if (opportunities && setNumOpps) {
      setNumOpps(opportunities.length);
    }
  }, [opportunities]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <OppFilters setLoading={setLoading} setOpportunities={setOpportunities} />
        </Grid>
        {loading && (
          <Grid item xs={12} sx={{ mb: 5 }} md={9}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="19vh" mt={{ xs: 0, md: 5 }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {!loading && opportunities.length > 0 && (
          <Grid item xs={12} sx={{ mb: 6 }} md={9}>
            <Box mt={{ xs: 0, md: 5 }}>
              <JustifiedBox>
                <Typography variant="subtitle1" mb={2.25}>
                  {opportunities.length} new {opportunities.length == 1 ? 'gig' : 'gigs'}
                </Typography>

                {authContext.user && (
                  <Typography
                    variant="subtitle1"
                    mb={2.25}
                    fontWeight={400}
                    onClick={() => navigate('/gigs/my-proposals')}
                    sx={{ textDecoration: 'none', color: theme.palette.primary.main, cursor: 'pointer' }}
                  >
                    View my proposals
                  </Typography>
                )}
              </JustifiedBox>

              {opportunities.map((opportunity: IOpportunity, index: number) => {
                //We unlock every third opportunity or lock them all if the list is 3 or less
                const lockableIndex = (index + 3) % 3 > 0 || opportunities.length < 4;
                //Never lock opportunities that are free to apply or if the user has a premium subscription
                const isOppLocked = !opportunity.isFree && !authContext.user?.subscriptionActive && lockableIndex;
                return (
                  <OpportunityAccordion
                    key={index}
                    opportunity={opportunity}
                    loggedIn={loggedIn}
                    updateOpportunity={updateOpportunity}
                    showTrackingIcon={true}
                    locked={isOppLocked}
                  >
                    <RoundButton
                      variant="contained"
                      endIcon={<EastIcon />}
                      onClick={() => {
                        applyNow(opportunity);
                      }}
                    >
                      {isOppLocked ? 'Unlock Now' : 'Send Proposal'}
                    </RoundButton>
                  </OpportunityAccordion>
                );
              })}
            </Box>
          </Grid>
        )}
        {!loading && opportunities.length === 0 && (
          <Grid item xs={12} sx={{ mb: 6 }} md={9}>
            <Box mt={{ xs: 0, md: 5 }}>
              <Typography variant="subtitle1">No gigs found</Typography>
              <Typography variant="body1" mt={2}>
                Sorry we couldn&apos;t find any gigs that match your search criteria.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <ApplyDialog
        open={applyDialogOpen}
        opportunity={selectedOpportunity}
        onClose={onApplyClose}
        updateOpportunity={updateOpportunity}
      />
    </>
  );
}
