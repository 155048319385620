import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  styled,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { IFrontUser } from 'global/interfaces/user';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { sendFreelancerEmail } from 'services/connectionService';
import { pushToDataLayer } from 'utils/tagHelper';
import { showError } from 'utils/errorHandler';
import { connectionRequestEvent } from 'global/constants';
import { Close } from '@mui/icons-material';
import { StyledDialogActions } from 'components/common/Dialog';
import { AuthContext } from 'contexts/AuthContext';

const TitleBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 24px;
  margin-bottom: 16px;
`;

const TitleAddDetBox = styled(TitleBox)`
  margin-bottom: 0px;
`;

interface IProjectDetailsProps {
  user: IFrontUser;
  open: boolean;
  setOpen: Dispatch<boolean>;
  setDetailsDrawer: Dispatch<boolean>;
}

export default function ProjectDetails({ setOpen, open, user, setDetailsDrawer }: IProjectDetailsProps) {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.user !== null;

  const form = useFormik<{ message: string; email: string; subject: string; name: string; userId: string }>({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
      userId: user.id,
    },
    validationSchema: object({
      name: string().max(200, 'Name must be at most 200 characters').required('You must enter your name'),
      message: string().max(2000, 'Message must be at most 2000 characters').required('You must enter a message'),
      email: string()
        .email('Enter a valid email address')
        .max(200, 'Email address must be at most 200 characters')
        .required('You must enter your email address'),
      subject: string().max(300, 'Subject must be at most 300 characters').required('You must enter a subject'),
      userId: string().required(),
    }),
    onSubmit: values => {
      setLoading(true);

      sendFreelancerEmail({
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
        userId: values.userId,
      })
        .then(() => {
          pushToDataLayer(connectionRequestEvent);
          setLoading(false);
          setSuccessMessage(true);
        })
        .catch(showError);
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      form.setFieldValue('name', authContext.user?.firstName + ' ' + authContext.user?.lastName);
      form.setFieldValue('email', authContext.user?.email);
    }
  }, [authContext.user]);

  const handleClose = () => {
    setOpen(false);
    setDetailsDrawer(false);
  };

  return (
    <SwipeableDrawer anchor="right" onClose={handleClose} open={open} onOpen={() => setOpen(true)}>
      {!successMessage && (
        <>
          <TitleAddDetBox>
            <Typography variant="h5">Email {user.firstName} </Typography>
            <IconButton aria-label="Close" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </TitleAddDetBox>
          <Box paddingLeft={3} paddingRight={3}>
            <Box>
              <Typography variant="body2" maxWidth={'490px'}>
                Please complete the contact form below. The freelancer will respond to you directly.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ maxWidth: '500px' }}>
            <Grid container pt={'24px'} spacing={3} justifyContent="center">
              <Grid item xs={11}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name="name"
                      fullWidth
                      label="Your Name"
                      value={form.values.name}
                      onChange={form.handleChange}
                      error={form.touched.name !== undefined && Boolean(form.errors.name)}
                      helperText={form.touched.name !== undefined ? form.errors.name : ''}
                      disabled={isLoggedIn}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={11}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name="email"
                      fullWidth
                      label="Your Email Address"
                      value={form.values.email}
                      onChange={form.handleChange}
                      error={form.touched.email !== undefined && Boolean(form.errors.email)}
                      helperText={form.touched.email !== undefined ? form.errors.email : ''}
                      disabled={isLoggedIn}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={11}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name="subject"
                      fullWidth
                      label="Subject"
                      value={form.values.subject}
                      onChange={form.handleChange}
                      error={form.touched.subject !== undefined && Boolean(form.errors.subject)}
                      helperText={form.touched.subject !== undefined ? form.errors.subject : ''}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={11}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name="message"
                      multiline
                      rows={5}
                      label="Message"
                      fullWidth
                      value={form.values.message}
                      onChange={form.handleChange}
                      error={form.touched.message !== undefined && Boolean(form.errors.message)}
                      helperText={form.touched.message !== undefined ? form.errors.message : ''}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <>
                <StyledDialogActions sx={{ marginTop: '8px', width: '100%' }}>
                  <Grid container justifyContent="flex-end" gap={2}>
                    <RoundButton variant="outlined" type="button" onClick={handleClose}>
                      Cancel
                    </RoundButton>

                    <RoundButton
                      loading={loading}
                      disabled={loading}
                      variant="contained"
                      type="button"
                      onClick={() => form.handleSubmit()}
                    >
                      Send
                    </RoundButton>
                  </Grid>
                </StyledDialogActions>
              </>
            </Grid>
          </Box>
        </>
      )}
      {successMessage && (
        <>
          <TitleAddDetBox>
            <Typography variant="h5">Message Sent</Typography>
            <IconButton aria-label="Close" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </TitleAddDetBox>
          <Box paddingLeft={3} paddingRight={3}>
            <Box>
              <Typography variant="body2">Your message has been sent. Please wait for a response.</Typography>
            </Box>
          </Box>
          <StyledDialogActions sx={{ marginTop: '8px', width: '100%' }}>
            <RoundButton variant="contained" type="button" onClick={handleClose}>
              Close
            </RoundButton>
          </StyledDialogActions>
        </>
      )}
    </SwipeableDrawer>
  );
}
