import { Grid, Skeleton, styled, useMediaQuery, useTheme } from '@mui/material';
import { Dispatch } from 'react';

import { BorderedBox } from '../../common/BorderedBox';

import { RoundButton } from 'components/common/Button/RoundButton';

import ProjectDetails from './ProjectDetails';
import { IFrontUser } from 'global/interfaces/user';
import { MailOutlined } from '@mui/icons-material';

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 20px;
`;

const StyledBorderedBox = styled(BorderedBox)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: '32px',
  },
}));

interface IUserConnectionSectionProps {
  openDetailsDrawer: () => void;
  user: IFrontUser;
  openConnectionDrawer: boolean;
  setOpenConnectionDrawer: Dispatch<boolean>;
  setDetailsDrawer: Dispatch<boolean>;
}

export const UserConnectionSection = ({
  user,
  setOpenConnectionDrawer,
  openConnectionDrawer,
  setDetailsDrawer,
}: IUserConnectionSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOnConnect = () => {
    setOpenConnectionDrawer(true);
  };
  return (
    <StyledBorderedBox showBorder={!isMobile}>
      <Grid container justifyContent="center" gap={2.5}>
        <RoundButton
          startIcon={<MailOutlined />}
          variant="contained"
          size="large"
          fullWidth
          onClick={() => handleOnConnect()}
        >
          Email
        </RoundButton>
      </Grid>

      <ProjectDetails
        user={user}
        open={openConnectionDrawer}
        setOpen={setOpenConnectionDrawer}
        setDetailsDrawer={setDetailsDrawer}
      />
    </StyledBorderedBox>
  );
};
