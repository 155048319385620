import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import HeroSection from 'components/info/Hero';
import { DetailContainer } from 'components/terms/Containers';
import personIm from 'assets/images/lps/personimage7.webp';
import { useTitle } from 'utils/router';

export default function BuyerTerms(): JSX.Element {
  useTitle('Terms and Conditions');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <HeroSection
        title={'Terms and Conditions'}
        subtext="Our terms and conditions outline the guidelines you agree to by creating an account with us."
        minHeight={isMobileScreen ? '260px' : '470px'}
        personImage={personIm}
      />
      <Box bgcolor="#F8F7F4">
        <Container maxWidth="lg">
          <DetailContainer>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>1. Definitions</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              1.1 Shoutt: Shoutt International Ltd, the operator and provider of the Shoutt service, with its principal
              place of business at 86-90 Paul Street, London, EC2A 4NE, UK. Contact: support@shoutt.co.
              <br />
              <br />
              1.2 Platform: The website and services operated by Shoutt, providing access to a curated feed of freelance
              opportunities sourced from third-party platforms and vetted sources. Shoutt does not facilitate hiring,
              contract formation, or payment processing between users.
              <br />
              <br />
              1.3 Gigs: Job postings or freelance opportunities sourced from external third-party platforms. These gigs
              are curated and vetted but remain subject to external availability, terms, and conditions.
              <br />
              <br />
              1.4 Users: Freelancers who subscribe to Shoutt to gain access to vetted freelance gigs.
              <br />
              <br />
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>2. Platform Services</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              2.1 Shoutt aggregates and curates freelance job opportunities from multiple sources, providing users with
              access to a streamlined list of high-quality gigs.
            </Typography>

            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              2.2 Shoutt does not facilitate hiring, contract agreements, payments, or any direct interactions between
              freelancers and potential clients. All engagements must be managed independently between users and the gig
              source.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              2.3 While Shoutt aims to provide accurate and relevant gigs, it cannot guarantee the availability,
              accuracy, or terms of any third-party job postings. Users are responsible for conducting their own due
              diligence before engaging with a gig.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              2.4 This product includes GeoLite2 data created by MaxMind, available from https://www.maxmind.com.
            </Typography>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>3. User Account</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              3.1 Users must create an account to access the Shoutt platform and are responsible for maintaining the
              security of their login credentials.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              3.2 Shoutt reserves the right to disable accounts that violate our terms or misuse the platform.
            </Typography>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>4. Payments & Subscription</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              4.1 Shoutt operates on a subscription model, granting users access to a continuously updated freelance gig
              feed. Subscription fees are non-refundable once charged.
              <br />
              <br />
              4.2 Users can pause their subscription at a 50% reduced rate for as long as they need, however access to
              selected gigs will be restricted.
              <br />
              <br />
              4.3 Since Shoutt does not process transactions between freelancers and clients, any payment disputes or
              issues must be resolved directly between the user and the gig source.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>5. Vetting & Gig Quality</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              5.1 To maintain a high standard, Shoutt vets freelancers before granting access to the platform. This
              ensures that gigs are only accessed by professionals with at least three years of experience.
              <br />
              <br />
              5.2 Shoutt also performs quality checks on gigs, filtering out low-quality, vague, or unrealistic job
              postings. However, Shoutt does not guarantee gig availability, response rates, or hiring outcomes.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>6. Third-Party Content & External Sites</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              6.1 Shoutt provides links and references to third-party platforms. These sites operate independently, and
              Shoutt has no control over their content, policies, or job availability.
              <br />
              <br />
              6.2 Users engage with external job postings at their own risk and should review third-party terms before
              applying.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>7. No Exclusivity & Independence</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              7.1 Shoutt does not act as an intermediary, recruiter, or hiring platform. Users are free to apply for
              jobs independently and are not restricted from engaging with external clients or opportunities.
              <br />
              <br />
              7.2 Shoutt does not take responsibility for communication, negotiations, or agreements made between
              freelancers and gig sources.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>8. Reviews & Feedback</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Users may provide feedback on gigs, which Shoutt may use for platform improvements. Any publicly shared
              testimonials become the property of Shoutt for marketing purposes.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>9. Limitation of Liability</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'} component={'div'}>
              9.1 Use of Shoutt is at your own risk. While we strive to provide high-quality gigs, we do not guarantee
              successful job placements, earnings, or hiring outcomes.
              <br />
              <br />
              9.2 Shoutt is not liable for:
              <ul>
                <li>The accuracy, completeness, or availability of third-party job listings</li>
                <li>Non-responsiveness or rejection from gig sources</li>
                <li>Any disputes arising between freelancers and clients</li>
                <li>Any financial losses incurred due to engagements with third parties</li>
              </ul>
              9.3 The platform may undergo maintenance or temporary outages. While we aim for continuous service, we are
              not liable for any disruption.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>10. Compliance & Acceptable Use</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              10.1 Users must use the platform solely for accessing vetted freelance gigs. Any attempts to misuse,
              resell, or distribute data outside the intended scope are strictly prohibited.
              <br />
              <br />
              10.2 Shoutt reserves the right to terminate access for violations of our terms.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>11. Account Cancellation & Termination</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              11.1 Users can downgrade their subscription to a free account at any time via their account settings. Upon
              downgrading, the user’s profile will remain listed on our talent network.
              <br />
              <br />
              11.2 Users can delete their account, profile and stop receiving email communications by deleting their
              account via their account settings menu. This option is not reversible.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>12. Governing Law</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              12.1 These terms are governed by the laws of the United Kingdom, but disputes may be resolved in any
              applicable jurisdiction.
            </Typography>
          </DetailContainer>
        </Container>
      </Box>
    </>
  );
}
