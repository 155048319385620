import { Close } from '@mui/icons-material';
import { Dialog, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { IPauseProps, PauseDialogProps } from './pauseSubscription';
import { StyledCTAButtton } from 'components/common/Button/CTAs';
import { useContext, useState } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { unpauseSubscription } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';

export function UnpauseSubscription(props: IPauseProps) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <StyledCTAButtton variant="contained" fullWidth onClick={() => setShowDialog(true)} loading={props.loading}>
        Unpause
      </StyledCTAButtton>
      <UnpauseDialog {...props} open={showDialog} onClose={() => setShowDialog(false)} />
    </>
  );
}

function UnpauseDialog(props: PauseDialogProps) {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleUnpause = () => {
    props.setLoading(true);
    unpauseSubscription()
      .then(() => authContext.refresh())
      .catch(showError)
      .finally(() => props.setLoading(false));
  };

  return (
    <Dialog
      open={props.open}
      onClose={!props.loading ? props.onClose : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
    >
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          Unpause Subscription
        </Typography>
        <IconButton onClick={() => (!props.loading ? props.onClose() : undefined)}>
          <Close />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        By unpausing your access to premium features will be restored immediately. You will be charged full price at
        your next billing cycle.
      </StyledDialogContent>
      <StyledDialogActions>
        <Grid container justifyContent={{ xs: 'center', sm: 'right' }} gap={1}>
          <Grid item xs={12} sm={4}>
            <RoundButton variant="outlined" fullWidth onClick={props.onClose} disabled={props.loading}>
              Cancel
            </RoundButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <RoundButton variant="contained" fullWidth type="submit" loading={props.loading} onClick={handleUnpause}>
              Unpause Now
            </RoundButton>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </Dialog>
  );
}
