import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';

import CategoryLookups from 'json/CategoryLookups.json';

interface CategoryMultiSelectProps extends SelectProps<string[]> {
  error?: boolean;
  helpertext?: string | string[];
  homeCategoriesOnly?: boolean;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ICategoryMultiSelectProps extends CategoryMultiSelectProps {
  compact?: boolean;
}

export default function CategoryMultiSelect({ ...props }: ICategoryMultiSelectProps): JSX.Element {
  let lookups = [...CategoryLookups];
  lookups.sort((a: { label: string }, b: { label: any }) => a.label.localeCompare(b.label));
  if (props.homeCategoriesOnly) {
    lookups = lookups.filter(p => p.home);
  }

  const labelSize = props.size && props.size == 'small' ? 'small' : undefined;

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-category-label" error={props.error} size={labelSize}>
        {props.label}
      </InputLabel>
      <Select
        {...props}
        labelId="multiple-category-label"
        multiple
        value={props.value}
        displayEmpty
        onChange={props.onChange}
        input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
        error={props.error}
        renderValue={selected => {
          if (selected?.length === 0 && props.placeholder) {
            return <Typography color="grey.400">{props.placeholder}</Typography>;
          }

          if (props.compact) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                <Chip key={selected[0]} label={selected[0]} size="small" />
                {selected.length > 0 && '...'}
              </Box>
            );
          }

          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={value} size="small" />
              ))}
            </Box>
          );
        }}
        MenuProps={MenuProps}
      >
        {lookups.map(val => (
          <MenuItem key={val.id} value={val.id}>
            <Checkbox checked={props.value ? props.value?.includes(val.id) : false} />
            <ListItemText primary={val.label} />
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText error>{props.helpertext}</FormHelperText>}
    </FormControl>
  );
}
