import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { SelectItem } from 'global/interfaces/selects';

interface CountrySelectProps {
  label: string;
  error?: boolean;
  helperText?: string;
  size: 'small' | 'medium';
  onChange?: (event: React.SyntheticEvent, value: { label: string; code: string } | null) => void;
  value?: string;
  disabled?: boolean;
}

export default function CountrySelect(props: CountrySelectProps): JSX.Element {
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.label === value.code && option.label === value.label}
      value={countryCodeValues[props.value ?? ''] ?? null}
      options={getCountryList()}
      onChange={props.onChange}
      autoHighlight
      size={props.size || 'medium'}
      getOptionLabel={option => option.label ?? ''}
      disabled={props.disabled}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={props.label}
          error={props.error}
          helperText={props.helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

interface CountrySelectMultipleProps {
  values: ICountryAutoComplete[];
  label: string;
  error?: boolean;
  helperText?: string;
  size: 'small' | 'medium';
  onChange?: (event: React.SyntheticEvent, value: ICountryAutoComplete[]) => void;
  disabled?: boolean;
}

export function CountrySelectMultiple(props: CountrySelectMultipleProps): JSX.Element {
  return (
    <Autocomplete
      multiple
      isOptionEqualToValue={(option, value) => option.code === value.code && option.label === value.label}
      value={props.values ?? null}
      options={getCountryLeadList()}
      onChange={props.onChange}
      autoHighlight
      size={props.size || 'medium'}
      getOptionLabel={option => option.label ?? ''}
      disabled={props.disabled}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={props.label}
          error={props.error}
          helperText={props.helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
  currency?: string;
  disablePayments?: boolean;
  group?: string;
}

interface CurrencyType {
  currency: string;
  currencySymbol: string;
  conversionRate: number; //Conversion rate to usd must match the backend for each currency in Constants.cs
}

export const fallbackCurrency: CurrencyType = {
  currency: 'USD',
  currencySymbol: '$',
  conversionRate: 1,
};

const currencies: readonly CurrencyType[] = [
  fallbackCurrency,
  {
    currency: 'GBP',
    currencySymbol: '£',
    conversionRate: 1.27,
  },
  {
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: 1.17,
  },
  {
    currency: 'CAD',
    currencySymbol: 'CA$',
    conversionRate: 0.75,
  },
];

export const fallbackContractCountry = {
  currency: 'USD',
  currencySymbol: '$',
  code: 'US',
};

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
// EU list from https://www.gov.uk/eu-eea#:~:text=The%20EU%20countries%20are%3A,%2C%20Slovenia%2C%20Spain%20and%20Sweden
export const countries: readonly CountryType[] = [
  { code: 'AD', label: 'Andorra', phone: '376', disablePayments: true, group: 'Europe' },
  { code: 'AI', label: 'Anguilla', phone: '1-264', disablePayments: true, group: 'North America' },
  { code: 'AL', label: 'Albania', phone: '355', disablePayments: true, group: 'Europe' },
  { code: 'AM', label: 'Armenia', phone: '374', disablePayments: true, group: 'Asia' },
  { code: 'AO', label: 'Angola', phone: '244', disablePayments: true, group: 'Africa' },
  { code: 'AQ', label: 'Antarctica', phone: '672', disablePayments: true, group: 'Antarctica' },
  { code: 'AR', label: 'Argentina', phone: '54', disablePayments: true, group: 'South America' },
  {
    code: 'AT',
    label: 'Austria',
    phone: '43',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    group: 'Oceania',
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
    disablePayments: true,
    group: 'Europe',
  },
  {
    code: 'BE',
    label: 'Belgium',
    phone: '32',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'BG', label: 'Bulgaria', phone: '359', currency: 'EUR', group: 'Europe' },
  { code: 'BO', label: 'Bolivia', phone: '591', disablePayments: true, group: 'South America' },
  { code: 'BR', label: 'Brazil', phone: '55', disablePayments: true, group: 'South America' },
  { code: 'BS', label: 'Bahamas', phone: '1-242', disablePayments: true, group: 'North America' },
  { code: 'BT', label: 'Bhutan', phone: '975', disablePayments: true, group: 'Asia' },
  { code: 'BV', label: 'Bouvet Island', phone: '47', disablePayments: true, group: 'South America' },
  { code: 'BW', label: 'Botswana', phone: '267', disablePayments: true, group: 'Africa' },
  { code: 'BY', label: 'Belarus', phone: '375', disablePayments: true, group: 'Europe' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    group: 'North America',
  },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225', disablePayments: true, group: 'Africa' },
  { code: 'CL', label: 'Chile', phone: '56', disablePayments: true, group: 'South America' },
  { code: 'CM', label: 'Cameroon', phone: '237', disablePayments: true, group: 'Africa' },
  { code: 'CN', label: 'China', phone: '86', disablePayments: true, group: 'Asia' },
  { code: 'CO', label: 'Colombia', phone: '57', disablePayments: true, group: 'South America' },
  { code: 'CR', label: 'Costa Rica', phone: '506', disablePayments: true, group: 'North America' },
  { code: 'CU', label: 'Cuba', phone: '53', disablePayments: true, group: 'North America' },
  { code: 'CV', label: 'Cape Verde', phone: '238', disablePayments: true, group: 'Africa' },
  { code: 'HR', label: 'Croatia', phone: '385', currency: 'EUR', disablePayments: true, group: 'Europe' },
  {
    code: 'CY',
    label: 'Cyprus',
    phone: '357',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
    phone: '420',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'DK', label: 'Denmark', phone: '45', currency: 'EUR', group: 'Europe' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
    disablePayments: true,
    group: 'North America',
  },
  { code: 'DZ', label: 'Algeria', phone: '213', disablePayments: true, group: 'Africa' },
  { code: 'EC', label: 'Ecuador', phone: '593', disablePayments: true, group: 'South America' },
  {
    code: 'EE',
    label: 'Estonia',
    phone: '372',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'EG', label: 'Egypt', phone: '20', disablePayments: true, group: 'Africa' },
  {
    code: 'FI',
    label: 'Finland',
    phone: '358',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'GI', label: 'Gibraltar', phone: '350', currency: 'GBP', group: 'Europe' },
  {
    code: 'GR',
    label: 'Greece',
    phone: '30',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'GD', label: 'Grenada', phone: '1-473', disablePayments: true, group: 'North America' },
  { code: 'GE', label: 'Georgia', phone: '995', disablePayments: true, group: 'Asia' },
  { code: 'HK', label: 'Hong Kong', phone: '852', group: 'Asia' },
  { code: 'HN', label: 'Honduras', phone: '504', disablePayments: true, group: 'North America' },
  { code: 'HU', label: 'Hungary', phone: '36', currency: 'EUR', group: 'Europe' },
  { code: 'ID', label: 'Indonesia', phone: '62', disablePayments: true, group: 'Asia' },
  {
    code: 'IE',
    label: 'Ireland',
    phone: '353',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'IT',
    label: 'Italy',
    phone: '39',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'IL', label: 'Israel', phone: '972', disablePayments: true, group: 'Asia' },
  { code: 'IN', label: 'India', phone: '91', disablePayments: true, group: 'Asia' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    group: 'Asia',
  },
  { code: 'JM', label: 'Jamaica', phone: '1-876', disablePayments: true, group: 'North America' },
  { code: 'JO', label: 'Jordan', phone: '962', disablePayments: true, group: 'Asia' },
  { code: 'KE', label: 'Kenya', phone: '254', disablePayments: true, group: 'Africa' },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
    disablePayments: true,
    group: 'Asia',
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82', disablePayments: true, group: 'Asia' },
  {
    code: 'LV',
    label: 'Latvia',
    phone: '371',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    phone: '423',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'LT',
    label: 'Lithuania',
    phone: '370',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    phone: '352',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'MT',
    label: 'Malta',
    phone: '356',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'MY', label: 'Malaysia', phone: '60', disablePayments: true, group: 'Asia' },
  { code: 'MX', label: 'Mexico', phone: '52', group: 'North America' },
  { code: 'MA', label: 'Morocco', phone: '212', disablePayments: true, group: 'Africa' },
  { code: 'ME', label: 'Montenegro', phone: '382', disablePayments: true, group: 'Europe' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
    disablePayments: true,
    group: 'Europe',
  },
  {
    code: 'NL',
    label: 'Netherlands',
    phone: '31',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'NZ', label: 'New Zealand', phone: '64', group: 'Oceania' },
  { code: 'NG', label: 'Nigeria', phone: '234', disablePayments: true, group: 'Africa' },
  { code: 'NO', label: 'Norway', phone: '47', group: 'Europe' },
  { code: 'PY', label: 'Paraguay', phone: '595', disablePayments: true, group: 'South America' },
  { code: 'PE', label: 'Peru', phone: '51', disablePayments: true, group: 'South America' },
  { code: 'PL', label: 'Poland', phone: '48', group: 'Europe' },
  {
    code: 'PT',
    label: 'Portugal',
    phone: '351',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'PH', label: 'Philippines', phone: '63', disablePayments: true, group: 'Asia' },
  { code: 'PK', label: 'Pakistan', phone: '92', disablePayments: true, group: 'Asia' },
  { code: 'RO', label: 'Romania', phone: '40', currency: 'EUR', group: 'Europe' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966', disablePayments: true, group: 'Asia' },
  { code: 'SG', label: 'Singapore', phone: '65', group: 'Asia' },
  { code: 'SK', label: 'Slovakia', phone: '421', currency: 'EUR', group: 'Europe' },
  { code: 'RS', label: 'Serbia', phone: '381', disablePayments: true, group: 'Europe' },
  { code: 'SN', label: 'Senegal', phone: '221', disablePayments: true, group: 'Africa' },
  { code: 'RU', label: 'Russia', phone: '7', disablePayments: true, group: 'Europe' },

  {
    code: 'SI',
    label: 'Slovenia',
    phone: '386',
    currency: 'EUR',
    group: 'Europe',
  },
  {
    code: 'ES',
    label: 'Spain',
    phone: '34',
    currency: 'EUR',
    group: 'Europe',
  },
  { code: 'SE', label: 'Sweden', phone: '46', currency: 'EUR', group: 'Europe' },
  { code: 'CH', label: 'Switzerland', phone: '41', group: 'Europe' },
  {
    code: 'TW',
    label: 'Taiwan, Province of China',
    phone: '886',
    disablePayments: true,
    group: 'Asia',
  },
  { code: 'TH', label: 'Thailand', phone: '66', group: 'Asia' },
  { code: 'TN', label: 'Tunisia', phone: '216', disablePayments: true, group: 'Africa' },
  { code: 'TO', label: 'Tonga', phone: '676', disablePayments: true, group: 'Oceania' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
    disablePayments: true,
    group: 'North America',
  },
  { code: 'TR', label: 'Turkey', phone: '90', disablePayments: true, group: 'Europe' },
  { code: 'UA', label: 'Ukraine', phone: '380', disablePayments: true, group: 'Europe' },
  { code: 'UG', label: 'Uganda', phone: '256', disablePayments: true, group: 'Africa' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971', group: 'Asia' },
  {
    code: 'GB',
    label: 'United Kingdom',
    phone: '44',
    suggested: true,
    currency: 'GBP',
    group: 'Europe',
  },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
    currency: fallbackContractCountry.currency,
    group: 'North America',
  },
  { code: 'UY', label: 'Uruguay', phone: '598', disablePayments: true, group: 'South America' },
  { code: 'ZA', label: 'South Africa', phone: '27', disablePayments: true, group: 'Africa' },
  { code: 'ZM', label: 'Zambia', phone: '260', disablePayments: true, group: 'Africa' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263', disablePayments: true, group: 'Africa' },
];

const cmp = function (a: ICountryAutoComplete, b: ICountryAutoComplete) {
  const aSuggested = a.suggested === true ? 1 : 0;
  const bSuggested = b.suggested === true ? 1 : 0;

  // Prioritize suggested countries
  if (aSuggested !== bSuggested) {
    return bSuggested - aSuggested;
  }

  // Sort alphabetically by label
  return a.label.localeCompare(b.label);
};

export interface ICountryAutoComplete {
  suggested?: boolean;
  group?: string;
  label: string;
  code: string;
}

const getCountryList = (): ReadonlyArray<ICountryAutoComplete> => {
  const mappedList = countries.map(function (i) {
    return {
      code: i.code,
      label: i.label,
      suggested: i.suggested,
    };
  });
  mappedList.sort(cmp);

  return mappedList;
};

const getCountryLeadList = (): ReadonlyArray<ICountryAutoComplete> => {
  const countriesWithRegion = [
    ...countries,
    {
      code: 'EU',
      label: 'Europe (Region)',
      group: 'Europe',
      suggested: true,
    },
  ];
  const mappedList = countriesWithRegion.map(function (i) {
    return {
      code: i.code,
      label: i.label,
      suggested: i.suggested,
      group: i?.group ?? '',
    };
  });
  mappedList.sort(cmp);

  return mappedList;
};

const countryCodeValues: { [key: string]: { label: string; code: string } } = Object.assign(
  {},
  ...countries.map(x => ({ [x.code]: { label: x.label, code: x.code } })),
);

/**
Filters the list of countries to include only those with unique currency and currency symbol.
@typedef {Object} CountryType - Represents a country with optional currency and currency symbol properties.
@property {string} name - The name of the country.
@property {string} [currency] - The currency of the country.
@property {string} [currencySymbol] - The symbol of the currency.
*/
/**

A list of countries with unique currency and currency symbol.
@constant
@type {CountryType[]}
/
export const countriesWithCurrency = countries.filter(/*
Filter callback to determine if the country has a unique currency and currency symbol.
@param {CountryType} country - The current country being processed.
@param {number} index - The index of the current country being processed.
@param {CountryType[]} self - The array of all countries.
@returns {boolean} - Returns true if the country has a unique currency and currency symbol.
*/
export const countriesWithCurrency: CountryType[] = countries.filter((country, index, self) => {
  if (country.currency) {
    return index === self.findIndex(c => c.currency === country.currency);
  }
  return false;
});

export const getCurrencySelectItems = () => {
  const arr: SelectItem[] = [];

  for (const currencyItem of currencies) {
    arr.push({
      id: currencyItem.currency!,
      label: currencyItem.currency!,
    });
  }

  return arr;
};

export const getCurrencyDetails = (contractCurrency: string) => {
  const currency = currencies.find(c => c.currency === contractCurrency);

  return currency ?? fallbackCurrency;
};

export const getCurrencySymbol = (contractCurrency: string | undefined) => {
  const upperCaseCurrency = contractCurrency ? contractCurrency.toUpperCase() : contractCurrency;
  const currency = currencies.find(c => c.currency === upperCaseCurrency);
  if (currency !== undefined) {
    return currency.currencySymbol;
  }
  return upperCaseCurrency ?? fallbackContractCountry.currencySymbol;
};

export const getCurrencySymbolByCountryCode = (countryCode: string) => {
  const country = countries.find(c => c.code === countryCode);
  const currencyItem = currencies.find(c => c.currency === country?.currency);
  return currencyItem?.currencySymbol;
};

export const getShowPaymentsByCountryCode = (countryCode: string) => {
  const country = countries.find(c => c.code === countryCode);
  const showPayments = !country?.disablePayments;
  return showPayments;
};
