import { useTheme } from '@mui/material/styles';

import { useLocation } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';

import HeroSection, { IReview, IUsp } from 'components/info/Hero';
import { desktopHeaderHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';
import UserSearch from 'components/common/Search/UserSearch';
import { ClockIcon } from 'components/icon/ClockIcon';

import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import { BriefIcon } from 'components/icon/BriefIcon';
import personIm from 'assets/images/lps/personimage2.webp';

const HeaderBanner = () => {
  const location = useLocation();
  const showHeaderGraphic = location.pathname.toLowerCase() === '/talent';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const review: IReview = {
    name: 'Diana C',
    roleTitle: 'Business Owner',
    reviewText: '"This makes it so much easier to find quality talent!"',
  };

  const usps: IUsp[] = [
    {
      icon: (
        <ClockIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Vetted Experts',
      text: 'Every freelancer on Shoutt has been reviewed for quality and experience.',
    },
    {
      icon: (
        <BriefIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Direct Messaging',
      text: 'Message and collaborate directly—Shoutt doesn’t sit between you and talent. No sign-up required.',
    },
    {
      icon: (
        <ExclusiveIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Hassle-Free Hiring',
      text: 'Search or browse to find the right freelancer in minutes, not days.',
    },
  ];

  return (
    <>
      {showHeaderGraphic && (
        <>
          <Box marginTop={!isMobile ? `${desktopHeaderHeight}px` : `${mobileHeaderHeight}px`}>
            <HeroSection
              title="Find top freelance talent without breaking a sweat"
              review={review}
              usps={usps}
              personImage={personIm}
              extraHeader={
                isMobile ? (
                  <Box pt="12px" mb="-8px">
                    <UserSearch />
                  </Box>
                ) : (
                  <></>
                )
              }
            />
          </Box>
        </>
      )}
    </>
  );
};

export default HeaderBanner;
