import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import Typography from '@mui/material/Typography';
import HeroSection from 'components/info/Hero';
import { DetailContainer } from 'components/terms/Containers';

import { useTitle } from 'utils/router';
import personIm from 'assets/images/lps/personimage6.webp';

export default function Policy(): JSX.Element {
  useTitle('Privacy Policy');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <HeroSection
        title={'Privacy Policy'}
        subtext="Our privacy policy explains how we handle, protect, and use your personal data."
        minHeight={isMobileScreen ? '260px' : '470px'}
        personImage={personIm}
      />

      <Box bgcolor="#F8F7F4">
        <Container maxWidth="lg">
          <DetailContainer>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>1. Introduction</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Shoutt International Ltd (hereinafter referred to as &quot;Shoutt,&quot; &quot;we,&quot; &quot;our,&quot;
              or &quot;us&quot;) s committed to safeguarding the privacy and protecting the personal data of our users.
              This Privacy Policy outlines how we collect, use, disclose, and store personal information, as well as
              your rights regarding your personal data when using the Shoutt platform. We adhere to the General Data
              Protection Regulation (GDPR) and other applicable data protection laws.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>2. Information We Collect</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              2.1 Personal Information: We may collect personal information such as your name, email address, and
              professional details when you sign up for a user account on Shoutt.
              <br />
              <br /> 2.2 User-Generated Content: Content you upload or post on the Platform, including text, images,
              videos, and other media, may be collected and stored.
              <br />
              <br /> 2.2 Portfolio and Work History: We collect information about your portfolio, work history, and
              qualifications to ensure Shoutt remains a high-quality platform for vetted professionals.
              <br />
              <br />
              2.3 Communication History: We collect and store your communication with Shoutt support or other
              interactions related to the platform. However, we do not track or store communications between freelancers
              and external clients.
              <br />
              <br /> 2.4 Third-Party Analytics: We use third-party analytics services, such as Google Analytics, to
              collect and analyze data related to your use of the platform. These services help us understand user
              behavior, improve our services, and enhance your experience. Please note that these third-party analytics
              services may collect information about your online activities over time and across different websites or
              online platforms.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>3. How We Use Your Information</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              3.1 Platform Services: We use your information to provide you with access to the Shoutt platform, create
              user accounts, and offer customer support.
              <br />
              <br /> 3.2 Communication: We may use your contact information to communicate with you regarding platform
              updates, notifications, and customer support inquiries.
              <br />
              <br /> 3.3 User Experience: We use data to personalize your experience on the platform and improve our
              services, including recommendations and content suggestions.
              <br />
              <br /> 3.4 Legal Compliance: We may process your data to comply with legal obligations, resolve disputes,
              and enforce our terms and policies.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>4. Information Sharing</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              4.1 With Other Users: Your profile information, such as your portfolio and qualifications, may be visible
              to other Shoutt users. <br />
              <br />
              4.2 Service Providers: We may share your data with trusted service providers who assist us in delivering
              our services, such as data analysis and customer support.
              <br />
              <br /> 4.3 Legal and Safety: We may disclose information to respond to legal requests, protect our rights
              and safety, and comply with legal requirements.
              <br />
              <br /> 4.4 Business Transfers: In the event of a merger, acquisition, or sale of assets, your information
              may be transferred to the acquiring entity.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>5. Data Storage and Security</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              5.1 Data Storage: Your information is stored on secure servers provided by Azure Web Services and the
              Microsoft cloud, which may be located in the United States and Europe. We implement technical and
              organizational measures to protect your data against unauthorized access, disclosure, or alteration.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>6. Your Rights</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              6.1 Access: You have the right to access the personal data we hold about you and request a copy.
              <br />
              <br /> 6.2 Rectification: You can request corrections to inaccurate or incomplete information we have
              about you.
              <br />
              <br />
              6.3 Erasure: You may request the deletion of your personal data when it is no longer necessary for the
              purposes for which it was collected.
              <br />
              <br /> 6.4 Data Portability: You have the right to receive your data in a structured, commonly used, and
              machine-readable format and transmit it to another data controller.
              <br />
              <br />
              6.5 Withdrawal of Consent: You can withdraw your consent for processing your data where consent is the
              legal basis for processing. <br />
              <br />
              6.6 Objection: You may object to the processing of your data based on legitimate interests, direct
              marketing, or automated decision-making.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>7. Contact Us</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              If you have questions, concerns, or requests related to your privacy or this Privacy Policy, please
              contact us at support@shoutt.co.
            </Typography>

            <Typography variant={isMobileScreen ? 'h6' : 'h5'}>8. Changes to this Privacy Policy</Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              We may update this Privacy Policy to reflect changes in our data practices or legal requirements. Any
              changes will be posted on the platform, and the revised policy will be effective from the date indicated
              at the top of the document.
              <br />
              <br /> By using the Shoutt platform, you agree to the terms outlined in this Privacy Policy. Please review
              this policy regularly for updates.
            </Typography>
          </DetailContainer>
        </Container>
      </Box>
    </>
  );
}
