import { Box, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import CategoryMultiSelect from 'components/common/Select/CategoryMultiSelect';
import { countries } from 'components/common/Select/CountrySelect';
import FormSelect from 'components/common/Select/FormSelect';
import { AuthContext } from 'contexts/AuthContext';
import { useFormik } from 'formik';
import { frequencyItems } from 'global/enums/newsletterFrequency';
import IApiError from 'global/interfaces/api';
import { INewsletterPreference } from 'global/interfaces/newsletter';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getNewsletterPreferences, subscribeToNewsletter } from 'services/newsletterService';
import { showError } from 'utils/errorHandler';

interface IManageNewsletterPrefProps {
  setUpdated: Dispatch<boolean>;
}

export default function ManageNewsLetterForm({ setUpdated }: IManageNewsletterPrefProps) {
  const { id } = useParams();
  const [pref, setPref] = useState<INewsletterPreference>();
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id && !authContext.user) {
      setLoading(false);
      return;
    }

    getNewsletterPreferences(id)
      .then((res: INewsletterPreference) => setPref(res))
      .catch((e: IApiError) => {
        if (e.status !== 404) {
          showError(e);
        }
      })
      .finally(() => setLoading(false));
  }, [id, authContext.user]);

  const form = useFormik<INewsletterPreference>({
    initialValues: {
      email: pref?.email ?? '',
      opportunitiesEnabled: pref?.opportunitiesEnabled ?? false,
      frequency: pref?.frequency ?? '',
      categories: pref?.categories ?? [],
    },
    enableReinitialize: true,
    onSubmit: values => {
      setUpdated(false);
      setLoading(true);
      subscribeToNewsletter(values)
        .then(() => setUpdated(true))
        .finally(() => setLoading(false));
    },
  });

  if (!authContext.user && !loading && !pref) {
    navigate('not-found');
    return <></>;
  }

  return (
    <>
      <Stack direction="column" gap={2} mt={2}>
        <Typography variant="h6">Send me emails for new gigs</Typography>
        <Box>
          <FormControlLabel
            control={
              <Switch
                onChange={form.handleChange}
                name="opportunitiesEnabled"
                checked={form.values.opportunitiesEnabled}
                size="medium"
              />
            }
            label=""
          />
        </Box>
        <Stack gap={0.5}>
          <Typography variant="h6" mt={2}>
            Categories
          </Typography>
          <Typography variant="body2">
            Please choose 1 or more categories that you would like to receive gigs in.
          </Typography>
        </Stack>
        <Box>
          <CategoryMultiSelect
            name="categories"
            value={form.values.categories}
            onChange={form.handleChange}
            variant={'outlined'}
            multiple
            size="small"
            disabled={!form.values.opportunitiesEnabled}
            fullWidth={false}
            helpertext={form.touched.categories !== undefined ? form.errors.categories : ''}
          />
        </Box>

        <Stack gap={0.5}>
          <Typography variant="h6" mt={2}>
            Frequency
          </Typography>
          <Typography variant="body2">We can send you a newsletter either daily or weekly.</Typography>
        </Stack>

        <FormSelect
          name="frequency"
          value={form.values.frequency}
          placeholder="Frequency"
          onChange={form.handleChange}
          fullWidth
          size="small"
          variant="outlined"
          error={form.touched.frequency === true && Boolean(form.errors.frequency)}
          sx={{ maxWidth: { md: '50%' } }}
          items={frequencyItems}
          disabled={!form.values.opportunitiesEnabled}
          helpertext={form.touched.frequency !== undefined ? form.errors.frequency : ''}
        />

        {authContext.user && (
          <>
            <Stack gap={0.5}>
              <Typography variant="h6" mt={2}>
                Country
              </Typography>
              <Typography variant="body2">
                You can update your country in{' '}
                <Link to={`/users/${authContext.user?.id}/edit`} style={{ textDecoration: 'none' }}>
                  your profile
                </Link>
              </Typography>
            </Stack>
            <TextField
              variant="outlined"
              disabled
              value={countries.find(c => c.code === authContext.user!.country)?.label}
              size="small"
              sx={{ maxWidth: { md: '50%' } }}
            />
          </>
        )}
      </Stack>
      <Stack mt={3} mb={3} direction="row" justifyContent="right" gap={1}>
        <Box width={{ xs: '50%', md: '25%' }}>
          <RoundButton loading={loading} variant="outlined" onClick={() => navigate('/gigs')} fullWidth>
            Cancel
          </RoundButton>
        </Box>
        <Box width={{ xs: '50%', md: '25%' }}>
          <RoundButton loading={loading} variant="contained" onClick={() => form.handleSubmit()} fullWidth>
            Save
          </RoundButton>
        </Box>
      </Stack>
    </>
  );
}
