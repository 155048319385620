import { Dialog, DialogContent, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ApplyStageType, IOpportunity, OpportunityType } from 'global/interfaces/opportunity';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';

import ApplyExternalOpportunity from './ApplyExternalOpportunity';
import ApplyShouttOpportunity from './ApplyShouttOpportunity';
import { payWalled } from 'services/opportunityService';
import { pushToDataLayer } from 'utils/tagHelper';
import { applyOpportunityPayWall } from 'global/constants';
import GoPremiumInfo from './GoPremiumInfo';
import { WinClientDetails } from './WinClientDetails';
import { UnpauseDetails } from './UnpauseDetails';

interface IApplyDialogProps {
  onClose: () => void;
  open: boolean;
  opportunity: IOpportunity | null;
  updateOpportunity?: (opportunity: IOpportunity) => void;
}

export const PriceFeatureTypography = styled(Typography)(() => ({
  lineHeight: '140%',
  fontSize: '16px',
  color: '#101828',
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '0px 24px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px !important',
    minHeight: 'calc(100svh - 200px)',
  },
}));

export default function ApplyDialog(props: IApplyDialogProps): JSX.Element {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const authContext = useContext(AuthContext);
  const canApply: boolean = (authContext.user?.subscriptionActive ?? false) || (props.opportunity?.isFree ?? false);

  const [isFinalScreen, setIsFinalScreen] = useState(false);

  const updateApplyStageOnClose = (newstage: ApplyStageType) => {
    props.onClose();
    setTimeout(() => {
      if (props.opportunity) {
        props.opportunity.applyStage = newstage;
        if (newstage == ApplyStageType.Applied) props.opportunity.applied = true;
        props.updateOpportunity && props.updateOpportunity(props.opportunity);
      }
    }, 500);
  };

  useEffect(() => {
    if (props.open && props.opportunity != null && !canApply) {
      pushToDataLayer(applyOpportunityPayWall, {
        transaction_id: props.opportunity.id,
      });
      payWalled(props.opportunity.id);
    }

    if (props.open) {
      setIsFinalScreen(false);
    }
  }, [props.opportunity, props.open]);

  const showUnpauseDetails = props.opportunity != null && !canApply && !isFinalScreen && authContext.user?.isPaused;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
      maxWidth={
        !canApply ||
        props.opportunity?.type == OpportunityType.Shoutt ||
        (props.opportunity?.type === OpportunityType.External && props.opportunity.isAssisted)
          ? 'md'
          : 'sm'
      }
    >
      {props.opportunity != null && !canApply && isFinalScreen && <GoPremiumInfo onClose={props.onClose} />}
      {props.opportunity != null && !canApply && !isFinalScreen && !authContext.user?.isPaused && (
        <WinClientDetails onClose={props.onClose} setIsFinalScreen={setIsFinalScreen} />
      )}
      {showUnpauseDetails && <UnpauseDetails onClose={props.onClose} />}
      {canApply && props.opportunity?.type === OpportunityType.External && !props.opportunity.isAssisted && (
        <ApplyExternalOpportunity
          open={props.open}
          opportunity={props.opportunity}
          onClose={props.onClose}
          updateApplyStageOnClose={updateApplyStageOnClose}
        />
      )}
      {((canApply && props.opportunity?.type === OpportunityType.Shoutt) ||
        (canApply && props.opportunity?.type === OpportunityType.External && props.opportunity.isAssisted)) && (
        <ApplyShouttOpportunity
          open={props.open}
          opportunity={props.opportunity}
          onClose={props.onClose}
          updateApplyStageOnClose={updateApplyStageOnClose}
        />
      )}
    </Dialog>
  );
}
