import { Alert, styled, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { AuthContext } from 'contexts/AuthContext';
import { VettingStatus } from 'global/enums/vettingStatus';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoIcon } from 'components/icon/InfoIcon';
import { WarningIcon } from 'components/icon/WarningIcon';
import { dismissVetting } from 'services/userService';
import { PremiumDialog } from 'components/opportunities/apply/GoPremiumInfo';

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: '12px',

  [theme.breakpoints.down('md')]: {
    borderRadius: '0px',
  },
}));

interface StyledInfoAlertProps {
  darker: boolean;
}

const StyledInfoAlert = styled(StyledAlert, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'darker',
})<StyledInfoAlertProps>(({ theme, darker }) => ({
  backgroundColor: darker ? theme.palette.grey[100] : theme.palette.grey[50],
}));

interface IVettingAlertProps {
  sx?: SxProps<Theme>;
  darkerInfo?: boolean;
}

export default function VettingAlert(props: IVettingAlertProps): JSX.Element {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [dismissed, setDismissed] = useState<boolean>(authContext.vettingMessageDismissed ?? false);
  const [showPremium, setShowPremium] = useState(false);

  const updateDismissed = () => {
    setDismissed(true);
    dismissVetting().then(() => authContext.setVettingMessageDismissed());
  };

  useEffect(() => {
    if (authContext.vettingMessageDismissed && !dismissed) {
      setDismissed(true);
    }
  }, [authContext.vettingMessageDismissed]);

  return (
    <>
      <PremiumDialog open={showPremium} onClose={() => setShowPremium(false)} />
      {authContext.user?.vettingStatus == VettingStatus.NotSubmitted && !dismissed && (
        <StyledInfoAlert
          severity="info"
          icon={<InfoIcon sx={{ color: theme.palette.grey[400] }} />}
          onClose={updateDismissed}
          darker={props.darkerInfo ?? false}
          sx={props.sx}
        >
          <>
            To get listed on our talent network and unlock access to all gigs, please create your{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => navigate(`/users/${authContext.user?.id}/edit?apply=true`)}
            >
              profile
            </span>{' '}
            and get verified.
          </>{' '}
          <span
            style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
            onClick={() => window.open(process.env.REACT_APP_MODERATION_URL ?? '', '_blank')}
          >
            Learn more.
          </span>{' '}
        </StyledInfoAlert>
      )}
      {authContext.user?.vettingStatus == VettingStatus.Approved && !dismissed && (
        <StyledAlert severity="success" sx={props.sx}>
          Your profile has been verified and you have full access to all gigs.{' '}
          <span
            style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
            onClick={() => window.open('https://help.shoutt.co/en/articles/3545218', '_blank')}
          >
            Learn more.
          </span>{' '}
        </StyledAlert>
      )}

      {authContext.user?.vettingStatus == VettingStatus.InProgress && (
        <StyledInfoAlert
          severity="info"
          icon={<InfoIcon sx={{ color: theme.palette.grey[400] }} />}
          sx={props.sx}
          darker={props.darkerInfo ?? false}
        >
          Your profile is currently being reviewed by our moderators, please allow 1 business day. Feel free to carry on
          editing in the meantime.{' '}
          <span
            style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
            onClick={() => window.open(process.env.REACT_APP_MODERATION_URL ?? '', '_blank')}
          >
            Learn more.
          </span>{' '}
        </StyledInfoAlert>
      )}
      {authContext.user?.vettingStatus == VettingStatus.UpdatesRequired && (
        <StyledAlert severity="warning" icon={<WarningIcon />} sx={props.sx}>
          Please update your profile according to the following feedback and then re-submit: {isMobile && <br />}
          <i style={{ paddingRight: '4px' }}>
            {'"'}
            {authContext.user?.feedback}
            {'"'}
          </i>
        </StyledAlert>
      )}
      {authContext.user?.vettingStatus == VettingStatus.Rejected && !dismissed && (
        <StyledInfoAlert
          severity="info"
          icon={<InfoIcon sx={{ color: theme.palette.grey[400] }} />}
          sx={props.sx}
          onClose={updateDismissed}
          darker={props.darkerInfo ?? false}
        >
          Your profile was declined so you cannot access Premium Direct gigs. You can still access Free and Premium
          gigs.{' '}
          <span
            style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
            onClick={() => window.open('https://help.shoutt.co/en/articles/3545218', '_blank')}
          >
            Learn more.
          </span>{' '}
        </StyledInfoAlert>
      )}
    </>
  );
}
