import React, { useContext, useState } from 'react';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from '../Dialog';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from '../Button/RoundButton';
import { StyledLoadingButton } from 'components/payouts/payoutHandler';
import { createProductCheckout, unpauseSubscription } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { pushToDataLayer } from 'utils/tagHelper';
import { beginCheckoutEvent } from 'global/constants';
import { AuthContext } from 'contexts/AuthContext';

export default function SubscriptionDialog({
  returnUrl,
  onClose,
}: {
  returnUrl: string | undefined;
  onClose: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);
  const authContext = useContext(AuthContext);
  const isPaused = authContext.user?.isPaused ?? false;

  const createSubscriptionSession = () => {
    setLoading(true);
    pushToDataLayer(beginCheckoutEvent, {
      transaction_id: authContext.user?.id,
    });
    createProductCheckout(`${returnUrl}?create-contract`)
      .then(res => {
        window.location.href = res;
      })
      .catch(e => {
        setLoading(false);
        showError(e);
      });
  };

  const handleUnpauseSubscription = () => {
    setLoading(true);
    unpauseSubscription()
      .then(() => authContext.refresh())
      .catch(showError)
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <StyledDialogTitle>
        <Stack direction="row">
          <Typography variant="h6" color={theme.palette.grey[900]}>
            Upgrade Plan
          </Typography>
        </Stack>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <Typography variant="body2">Upgrade to the premium plan to start creating contracts.</Typography>
        <Typography variant="body2">
          The Shoutt Premium plan costs {localCurrencySymbol}
          {price} / month with no long term contract and a 30 day money back guarantee. We don’t charge any commission
          on transactions. A small Stripe payment processing fee of 1-3% applies, which is automatically deducted from
          payouts only.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => onClose()}>
          Cancel
        </RoundButton>
        <StyledLoadingButton
          fullWidth={isMobileScreen}
          loading={loading}
          variant="contained"
          onClick={() => (!isPaused ? createSubscriptionSession() : handleUnpauseSubscription())}
        >
          {!isPaused ? 'Upgrade Now' : 'Unpause'}
        </StyledLoadingButton>
      </StyledDialogActions>
    </React.Fragment>
  );
}
