import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Grid container marginTop={5}>
      <Grid item xs={12}>
        <Typography textAlign="center" variant="h5">
          Page Not Found
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid xs={6}>
          <Typography textAlign="center" variant="body1" pt={2}>
            Oops, looks like you&apos;ve taken a wrong turn in the land of Shoutt! 🗺️🚀
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" marginTop={2}>
        <Button variant="contained" onClick={() => navigate('/')}>
          Return home
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound;
