import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import NewFAQ from 'components/common/FAQs/NewFAQ';

import { billingFaqOptions as faqs } from 'components/common/FAQs/allData';

import { redirectToSignUpSeller, useTitle } from 'utils/router';
import PricingTable from 'components/common/Subscription/PricingTable';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { StyledCTAButtton } from 'components/common/Button/CTAs';
import { useLocation, useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

export default function Pricing() {
  useTitle('Pricing');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol } = useLocalization(19.99);
  const navigate = useNavigate();
  const location = useLocation();
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);
  const authContext = useContext(AuthContext);

  return (
    <Box style={{ backgroundColor: '#F8F7F4', paddingTop: isMobileScreen ? '32px' : '48px' }}>
      <Container maxWidth="lg">
        <Box>
          <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Pricing</Typography>
          <Typography variant={'body1'} fontSize={{ xs: '16px' }} mt={0.5}>
            Our affordable pricing gives you instant access to a wide variety of hand picked gigs for less than the cost
            of a pizza!
          </Typography>
          <Box mt={3}>
            <PricingTable
              isSeller={false}
              currencySybmol={localCurrencySymbol}
              premPrice={price}
              freeBgColor="#F9FAFB"
              freeCTA={
                <>
                  {!authContext.user && (
                    <StyledCTAButtton
                      variant="contained"
                      sx={{
                        mt: 2,
                        width: '100%',
                      }}
                      onClick={() => redirectToSignUpSeller(navigate, location)}
                      endIcon={<EastIcon />}
                      fullWidth
                    >
                      Get Started
                    </StyledCTAButtton>
                  )}
                </>
              }
              premCTA={
                <>
                  {!authContext.user && (
                    <StyledCTAButtton
                      variant="contained"
                      sx={{
                        mt: 2,
                        width: '100%',
                      }}
                      onClick={() => redirectToSignUpSeller(navigate, location)}
                      endIcon={<EastIcon />}
                      fullWidth
                    >
                      Get Started
                    </StyledCTAButtton>
                  )}
                </>
              }
            />
          </Box>
        </Box>
      </Container>
      <NewFAQ description="Everything you need to know about Shoutt." options={faqs} />
    </Box>
  );
}
