import { Avatar, Box, Container, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import im from 'assets/images/lps/img-bg-desktop-hero-grad.jpg';
import im2 from 'assets/images/lps/img-bg-mobile-hero.jpg';

import personIm from 'assets/images/lps/personimage.webp';

export const StyledBannerBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#4D7AFF',
  backgroundImage: `url(${im})`,
  backgroundSize: 'cover',
  backgroundPosition: 'right',
  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${im2})`,
  },
}));

export const BannerStack = styled(Stack)(({ theme }) => ({
  paddingTop: '32px',
  paddingBottom: '36px',

  [theme.breakpoints.up('md')]: {
    paddingTop: '40px',
    paddingBottom: '40px',
  },
}));

interface StyledAvatarProps {
  large?: boolean;
}

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'large',
})<StyledAvatarProps>(({ large }) => ({
  width: large ? '50px' : '44px',
  height: large ? '50px' : '44px',
  backgroundColor: '#E4E6FF',
}));

export const FeatureText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: '600',
  color: '#101828',

  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    lineHeight: '22px',
  },
}));

export const FeatureSubText = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '400',
  color: '#344054',
}));

export const FeatureContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: 'white',
  gap: '10px',
  padding: '12px 16px 12px 16px',
  border: '1px solid #E5E5E5',
  borderRadius: '7px',

  boxShadow: '0px 2px 12px 0px #1721551A',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    padding: '0px 0px 0px 0px',
  },
}));

export const MultiLineFeatureContainer = styled(Stack)(() => ({
  alignItems: 'flex-start',
  padding: '16px',
  gap: '16x',
  borderRadius: '12px',
  border: '1px solid  #FFF',
  height: '100%',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 0px 12px 0px #0000000F',
}));

export interface IReview {
  name: string;
  roleTitle: string;
  reviewText: string;
}

export interface IUsp {
  icon: React.ReactNode;
  title: string;
  text: string;
}

export interface HeroSectionProps {
  title: string;
  subtext?: string;
  extraHeader?: React.ReactNode;
  personImage?: string;
  review?: IReview;
  usps?: IUsp[];
  minHeight?: string;
}

export default function HeroSection({
  title,
  personImage,
  review,
  usps,
  extraHeader,
  subtext,
  minHeight,
}: HeroSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const heroImage = personImage ? personImage : personIm;
  return (
    <StyledBannerBox>
      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          backgroundImage: !isMobile ? `url(${heroImage})` : undefined,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
        }}
      >
        {extraHeader}
        <BannerStack
          direction={'column'}
          rowGap={2}
          sx={{ zIndex: 1 }}
          alignItems={'center'}
          flexDirection={usps ? 'column' : 'row'}
          minHeight={minHeight ? minHeight : undefined}
        >
          <Grid container columnSpacing={6}>
            <Grid item xs={12} md={7.5}>
              <Typography
                variant="h3"
                fontWeight={500}
                fontSize={{ xs: '28px', md: '42px', lg: '48px' }}
                color={theme.palette.common.white}
                maxWidth={'900px'}
              >
                {title}
              </Typography>
              {subtext && (
                <Box mt="8px" display={'flex'} alignItems={'center'}>
                  <Typography
                    variant="body1"
                    fontSize={{ xs: '16px', lg: '20px' }}
                    fontWeight={400}
                    color={theme.palette.common.white}
                    component={'span'}
                  >
                    {subtext}
                  </Typography>
                </Box>
              )}
              {review && (
                <>
                  <Box mt="32px" display={'flex'} alignItems={'center'}>
                    {review.name.length > 0 && (
                      <Typography
                        variant="subtitle1"
                        fontSize={{ xs: '18px', lg: '20px' }}
                        fontWeight={600}
                        color={theme.palette.common.white}
                        component={'span'}
                      >
                        {review.name}
                      </Typography>
                    )}
                    <Typography
                      variant="caption"
                      fontSize="12px"
                      fontWeight={400}
                      color={theme.palette.common.white}
                      component={'span'}
                      marginLeft={review.name.length > 0 ? '6px' : '0px'}
                      sx={{
                        background: '#5C548D',
                        padding: '4px 8px 4px 8px',
                        borderRadius: '4px',
                        boxShadow: '0px 0px 12px 0px #0000000F',
                        gap: '10px',
                        opacity: '0px',
                      }}
                    >
                      {review.roleTitle}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    fontSize={{ xs: '16px', lg: '20px' }}
                    color={theme.palette.common.white}
                    maxWidth={'800px'}
                    mt={'8px'}
                  >
                    {review.reviewText}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          {usps && (
            <Grid container mt={{ xs: '16px', md: '0px' }} spacing={isMobile ? '14px' : '22px'}>
              {usps.map((usp, index: number) => (
                <Grid item xs={12} md={4} key={index}>
                  <MultiLineFeatureContainer direction={'row'}>
                    <Stack direction={'column'} gap={'4px'} spacing={0.75}>
                      <Stack direction={'row'} spacing={1}>
                        {usp.icon}
                        <FeatureText>{usp.title}</FeatureText>
                      </Stack>

                      <FeatureSubText>{usp.text}</FeatureSubText>
                    </Stack>
                  </MultiLineFeatureContainer>
                </Grid>
              ))}
            </Grid>
          )}
        </BannerStack>
      </Container>
    </StyledBannerBox>
  );
}
