import { Box, CircularProgress, Container, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import MyOpportunityAccordion from 'components/opportunities/myApplications/myApplicationAccordion';
import MyOppFilters from 'components/opportunities/myApplications/myOppFilters';
import { IOpportunity } from 'global/interfaces/opportunity';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMyApplications } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export default function MyApplications() {
  useTitle('My Proposals');
  const [loading, setLoading] = useState(true);
  const [opps, setOpps] = useState<IOpportunity[]>([]);
  const [filteredOpps, setFilteredOpps] = useState<IOpportunity[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  useEffect(() => {
    getMyApplications()
      .then(res => {
        setOpps(res);
        setFilteredOpps(res);
      })
      .catch(showError)
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} pt={2}>
        {isMobile && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              fontWeight={400}
              onClick={() => navigate('/gigs')}
              sx={{ textDecoration: 'none', color: theme.palette.primary.main, cursor: 'pointer' }}
            >
              View all gigs
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <MyOppFilters opps={opps} setFilteredOpps={setFilteredOpps} setLoading={setLoading} />
        </Grid>
        {loading && (
          <Grid item xs={12} sx={{ mb: 5 }} md={9}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="19vh" mt={{ xs: 0, md: 5 }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {!loading && (
          <>
            <Grid item xs={12} md={9} sx={{ mb: 6 }}>
              <Box mt={{ xs: 0, md: 5 }}>
                <JustifiedBox>
                  <Typography variant="subtitle1" mb={2.25}>
                    {filteredOpps.length} {filteredOpps.length == 1 ? 'proposal' : 'proposals'} in the last 30 days
                  </Typography>
                  {!isMobile && (
                    <Typography
                      variant="subtitle1"
                      fontWeight={400}
                      onClick={() => navigate('/gigs')}
                      sx={{ textDecoration: 'none', color: theme.palette.primary.main, cursor: 'pointer' }}
                      mb={2.25}
                    >
                      View all gigs
                    </Typography>
                  )}
                </JustifiedBox>
                {filteredOpps.map((opportunity: IOpportunity, index: number) => (
                  <MyOpportunityAccordion key={index} opportunity={opportunity}></MyOpportunityAccordion>
                ))}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}
