import { Box, Grid, IconButton, Typography } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { Dispatch, useContext, useState } from 'react';
import { Close } from '@mui/icons-material';
import { pauseSubscription } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { AuthContext } from 'contexts/AuthContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelContent, { cancelContentHeader } from './cancelContent';
import InactiveDiscountDetails from './inactiveDiscountDetails';
import { UserSubscription } from 'global/interfaces/userSubscription';
interface IActiveDiscountDetailsProps {
  loading: boolean;
  setLoading: Dispatch<boolean>;
  loadSubscription: () => void;
  handleClose: () => void;
  subscription: UserSubscription | undefined;
}

export function ActiveDiscountDetails({
  loading,
  handleClose,
  setLoading,
  loadSubscription,
  subscription,
}: IActiveDiscountDetailsProps) {
  const [downgrade, setDowngrade] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const authContext = useContext(AuthContext);

  const handleCouponClick = () => {
    setLoading(true);
    pauseSubscription()
      .then(() => {
        setShowSuccess(true);
        setLoading(false);
      })
      .finally(() => setLoading(false))
      .catch(showError);
  };

  const closeTasks = () => {
    setLoading(true);
    authContext
      .refresh()
      .then(() => {
        loadSubscription();
        handleClose();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {showSuccess ? (
        <SuccessComponent handleClose={closeTasks} loading={loading} />
      ) : (
        <>
          {!downgrade ? (
            <>
              <StyledDialogTitle>
                <Typography variant="h6" color="grey.900">
                  {cancelContentHeader}
                </Typography>
                <IconButton onClick={() => (!loading ? handleClose() : undefined)}>
                  <Close />
                </IconButton>
              </StyledDialogTitle>
              <StyledDialogContent dividers>
                <CancelContent />
              </StyledDialogContent>
              <StyledDialogActions>
                <Grid container justifyContent="right" gap={1}>
                  <Grid item xs={12} md={6}>
                    <RoundButton variant="outlined" fullWidth onClick={() => setDowngrade(true)} disabled={loading}>
                      Continue to Downgrade
                    </RoundButton>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RoundButton
                      variant="contained"
                      fullWidth
                      type="submit"
                      loading={loading}
                      onClick={handleCouponClick}
                    >
                      Pause at 50% Discount
                    </RoundButton>
                  </Grid>
                </Grid>
              </StyledDialogActions>
            </>
          ) : (
            <InactiveDiscountDetails
              loading={loading}
              setLoading={setLoading}
              loadSubscription={loadSubscription}
              handleClose={handleClose}
              subscription={subscription}
            />
          )}
        </>
      )}
    </>
  );
}

function SuccessComponent({ handleClose, loading }: { handleClose: () => void; loading: boolean }) {
  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          Subscription Paused
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <Box>
          <Typography variant="body1" component="span">
            Thanks for choosing to stay with Shoutt. We have applied a 50% discount to your subscription. This will
            continue whilst your account is paused
          </Typography>
          <CheckCircleIcon color="success" sx={{ ml: 1, mb: -0.5 }} />
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton variant="outlined" onClick={() => handleClose()} loading={loading}>
          Close
        </RoundButton>
      </StyledDialogActions>
    </>
  );
}
